/**
 * Formats a number as a currency string 
 * @param {Number|String} value 
 * @returns 
 */
export const currency = (value) => {
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
  }).format(value);
};

/**
 * Capitalises the first letter of a string
 * @param {String} str 
 * @returns 
 */
export const capitalize = (str) => {
  if(!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Removes a leading slash from a string
 * @param {String} str 
 * @returns 
 */
export const noStartSlash = (str) => {
  if (!str || str.charAt(0) !== "/") {
    return str;
  }
  return str.slice(1);
}