export const roles = {
  THRIVE_ADMIN: "Thrive4Life Admin",
  COMPANY_ADMIN: "Company Admin",
  DEPARTMENT_ADMIN: "Department Admin",
  END_USER: "User",
};

export const getAllowedRoles = (user) => {
  if (user.isThriveAdmin) {
    return [
      roles.THRIVE_ADMIN,
      roles.COMPANY_ADMIN,
      roles.DEPARTMENT_ADMIN,
      roles.END_USER,
    ];
  }
  if (user.isCompanyAdmin) {
    return [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN, roles.END_USER];
  }
  if (user.isDepartmentAdmin) {
    return [roles.END_USER];
  }
  return [];
};

export const issueValues = {
  NOISSUE: "noissue",
  ASSESSED: "assessed",
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved",
};

export const assessmentStates = {
  PENDING: "Pending",
  COMPLETED: "Completed",
};
