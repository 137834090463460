<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">
        {{ title }}
        <font-awesome-icon
          :icon="infoIcon"
          class="infoTip"
          @mouseover="showTip = true"
          @mouseleave="showTip = false"
          @click="fixTip ? (fixTip = showTip = false) : (fixTip = true)"
        />
      </h3>
      <p class="value">
        <Spinner v-if="!hasValue" size="2rem" />
        {{ value }}
      </p>
      <p v-if="showTip || fixTip" class="statInfo">{{ statInfo }}</p>
    </div>
  </div>
</template>

<script>
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "StatTile",
  props: {
    title: String,
    value: String || Number,
    icon: Array,
    statInfo: String,
  },
  data() {
    return {
      showTip: false,
      fixTip: false,
    };
  },
  computed: {
    infoIcon() {
      return faCircleInfo;
    },
    hasValue() {
      return this.value && this.value !== "";
    },
  },
};
</script>

<style scoped>
.loader {
  height: 1rem;
  margin: 0;
}
.value {
  font-size: 2.5rem;
}
.infoTip {
  cursor: help;
}
.statInfo {
  background: #32325d;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9rem;
}
</style>