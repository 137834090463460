<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground" :logo="logo" short-title="Thrive4Life" title="Thrive4Life">
      <template v-slot:links>
        <sidebar-item :link="{
          name: 'Dashboard',
          path: '/dashboard',
        }" v-if="user" />

        <sidebar-item :link="{
          name: 'Assessments',
          path: '/assessments',
        }" v-if="user && !user.isEndUser" />
        <sidebar-item :link="{
          name: 'Users',
          path: '/users',
        }" v-if="user && !user.isEndUser" />
        <sidebar-item :link="{
          name: 'Companies and Departments',
          path: '/companydepartments',
        }" v-if="user && (user.isThriveAdmin || user.isCompanyAdmin)" />
        <sidebar-item :link="{
          name: 'Licence Management',
          path: '/licence-management',
        }" v-if="user && (user.isThriveAdmin || user.isCompanyAdmin)" />
        <sidebar-item :link="{
          name: 'Reports',
          path: '/reports',
        }" v-if="user && !user.isEndUser" />
        <sidebar-item :link="{
          name: 'Reminder Emails (admin)',
          path: '/reminder-emails',
        }" v-if="user && user.isThriveAdmin" />

        <sidebar-item :link="{
          name: 'Logout',
          path: '/logout',
        }" />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <DashboardHeader class="pt-5" />
      <div @click="toggleSidebar" class="content-view mt-0 mt-md--7">
        <!-- your content here -->
        <router-view class="mb-4"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardHeader from "../components/DashboardHeader.vue";
export default {
  components: {
    DashboardHeader,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    logo() {
      const u = this.user;
      return u ? u.company.media : "";
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style scoped>
.main-content {
  background-image: linear-gradient(175deg,
      #2dce89 0,
      #2dcecc 40%,
      #5d93d9 40%,
      #5d93d9 100%);
  background-size: 100% 400px;
  background-repeat: no-repeat;
  background-color: #5d93d9;
  min-height: 100vh;
}
</style>
