<template>
  <modal v-model:show="isVisible" modalClasses="modal-lg" @close="close">
    <h3>Edit Information</h3>
    <div v-if="data">
      <div class="form-group">
        <base-input type="text" label="Name" class="form-control" v-model="data.name" />
      </div>
      <div class="form-group">
        <base-input type="text" label="Email" class="form-control" v-model="data.email" />
      </div>

      <div class="form-group">
        <label for="company-select-3" class="form-control-label">Company</label>
        <select id="company-select-3" class="form-control" @change="getDepartments()" v-model="selectedCompany"
          v-if="companies.length > 1">
          <option v-for="company in companies" v-bind:key="company.id" v-bind:value="company.id">
            {{ company.name }}
          </option>
        </select>
        <base-input type="text" disabled readonly :value="companies[0].name" class="form-control"
          v-if="companies.length === 1" />
      </div>

      <div class="form-group">
        <label for="department-select-3" class="form-control-label">Department</label>
        <select id="department-select-3" class="form-control" v-model="selectedDepartment"
          v-if="departments.length > 1">
          <option v-for="department in departments" v-bind:key="department.id" v-bind:value="department.id">
            {{ department.name }}
          </option>
        </select>
        <base-input type="text" disabled readonly class="form-control" :value="departments[0].name"
          v-if="departments.length === 1" />
      </div>

      <div class="form-group">
        <label for="role-select-3" class="form-control-label">Role</label>
        <select id="role-select-3" class="form-control" v-model="selectedRole" v-if="roles.length > 1">
          <option v-for="role in roles" v-bind:key="role">
            {{ role }}
          </option>
        </select>
        <base-input type="text" disabled readonly class="form-control" :value="roles[0]" v-if="roles.length === 1" />
      </div>

      <div class="button-row">
        <!-- <base-button type="warning" @click="deleteUser"
          >Delete User</base-button
        > -->
        <base-button type="primary" @click="saveChanges">
          Save Changes
        </base-button>
        <base-button outline type="primary" @click="close">Cancel</base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import { getAllowedRoles } from "../../helpers/constants";
export default {
  props: ["user"],
  data() {
    return {
      isVisible: false,
      showPopUp: "",
      popUpType: "default",
      data: null,
      companies: [],
      departments: [],
      roles: [],
      selectedCompany: "",
      selectedDepartment: "",
      selectedRole: "",
    };
  },
  methods: {
    show(data) {
      if (!this.user) {
        console.error("User must be set first");
        return;
      }

      if (this.user.isEndUser) {
        throw "Only for admin users";
      }

      this.isVisible = true;
      this.data = data;
      this.loadExtraData();
    },
    close() {
      this.isVisible = false;
      this.data = null;
      this.clearExtraData();
      this.$emit("close");
    },
    clearExtraData() {
      // don't clear roles, companies - they depend on the current user and don't change
      this.selectedRole = "";

      if (this.user.isThriveAdmin) {
        this.departments = []; // all other user groups can re-use this data
        this.selectedDepartment = "";
      }
    },
    loadExtraData() {
      this.clearExtraData();

      // roles
      if (this.roles.length === 0) {
        this.roles = getAllowedRoles(this.user);
      }

      // preselect the user's own details
      this.selectedCompany = this.data.company.id;
      this.selectedDepartment = this.data.department.id;
      this.selectedRole = this.roles.includes(this.data.role)
        ? this.data.role
        : this.roles[0];

      // companies
      // we keep as much data pre-set as possible
      if (this.companies.length === 0) {
        if (this.user.isThriveAdmin) {
          axios.get("/api/companies").then((res) => {
            this.companies = res.data.companies;
          });
        } else {
          this.companies.push(this.user.company);
        }
      }

      // departments
      // we keep as much data pre-set as possible
      if (this.departments.length === 0) {
        if (this.user.isDepartmentAdmin) {
          this.departments.push(this.user.department);
        } else {
          this.getDepartments(parseInt(this.selectedCompany), false);
        }
      }
    },
    saveChanges() {
      const payload = {
        name: this.data.name,
        email: this.data.email,
        company: "" + this.selectedCompany, // send as string
        department: "" + this.selectedDepartment, // send as string
        role: this.selectedRole,
      };
      axios
        .put("/api/users/" + this.data.id, payload)
        .then((res) => {
          if (res.status === 200) {
            location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDepartments(id = null, clearSelectedDepartment = true) {
      if (id === null) {
        id = this.selectedCompany;
      }
      this.departments = [];

      if (clearSelectedDepartment) {
        this.selectedDepartment = "";
      }

      axios.get("/api/companies/" + id).then((res) => {
        this.departments = res.data.departments;

        if (clearSelectedDepartment) {
          this.selectedDepartment = this.departments[0].id;
        }
      });
    },
    // deleteUser() {
    //   axios
    //     .delete("/api/users/" + this.data.id)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         location.reload();
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
  },
};
</script>

<style scoped>
.single-option {
  border: 1px solid #789cff;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.single-option-text {
  align-self: center;
  padding-left: 10px;
  font-size: 0.85rem;
}

.single-option-checkbox {
  width: 18px;
  height: 18px;
}

.profile-modal {
  display: none;
  position: absolute;
  z-index: 150;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.edit-field {
  display: flex;
}

.edit-field>label {
  align-self: center;
  width: 12rem;
}

.placeholder-option {
  color: #8f8f8f;
  margin: 0;
}

.modal-content {
  background-color: #fefefe;
  margin: 10vw auto;
  border: 1px solid #2e2e2e;
  border-radius: 1vw;
  width: 30vw;
  font-family: "Open Sans", sans-serif;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.close-section {
  text-align: center;
  position: relative;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.profile-modal-title {
  font-weight: bold;
  font-size: 1.5vw;
  margin-top: 1vw;
  margin-right: 5vw;
  margin-bottom: 0.5rem;
}

.user-creation {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #789cff;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-row {
  margin-top: 1vw;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.close-btn {
  padding: 0.25rem 0.75rem;
  margin: 0;
  background-color: #f1f1f1;
  height: fit-content;
  font-size: 1.5rem;
  color: #2e2e2e;
  border-radius: 0.5vw;
  background-color: rgba(255, 84, 62, 0.81);
  border-color: rgba(255, 84, 62, 0.81);
  font-weight: 400;
  cursor: pointer;
}

.submit-btn {
  padding: 0.25rem 0.75rem;
  margin: 0;
  background-color: #789cff;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  border-radius: 0.5vw;
  font-weight: 400;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #416bde;
}

.close-btn:hover {
  background-color: red;
}

@media (max-width: 1000px) {
  .modal-content {
    margin: auto auto 30vw;
    width: 70%;
    padding: 50px;
  }

  .profile-modal-title {
    font-size: 4vw;
    margin-bottom: 3vw;
  }

  .close-btn,
  .submit-btn {
    font-size: 3.5vw;
    height: fit-content;
    width: 28vw;
    border-radius: 2vw;
    margin-top: 4vw;
    padding: 2.5vw 5vw;
  }
}
</style>
