<template>
  <div class="container-fluid pt-5 pt-md-8">
    <Spinner v-if="!fullData" />
    <div class="row mb-4" v-if="fullData">
      <div :class="{ 'col-xl-6': showSummary, col: !showSummary }">
        <UserTile
          :name="fullData?.user.name"
          :date="fullData?.complete_date"
          :company="fullData?.user.company.name"
          :email="fullData?.user.email"
          :department="fullData?.user.department.name"
          :assessment="fullData?.assessment.name"
        ></UserTile>
      </div>
      <div class="col-xl-6 mt-4 mt-xl-0" v-if="showSummary">
        <ResultsSummary
          :risks="fullData?.severity_data.resolved"
          :followup="fullData?.severity_data.assessed"
          :high="fullData?.severity_data.high"
          :low="fullData?.severity_data.low"
          :medium="fullData?.severity_data.medium"
          :severity="fullData?.severity_data.severity"
          :unresolved="fullData?.severity_data.unresolved"
        ></ResultsSummary>
      </div>
    </div>
    <div class="row" v-if="fullData">
      <div class="col">
        <simple-answer
          class="mt-4"
          v-for="answer in answers"
          v-bind:key="answer.id"
          v-bind:id="answer.id"
          v-bind:severity="answer.severity"
          v-bind:question="answer.question"
          v-bind:answer="answer.answer"
          v-bind:solution="answer.solution"
          v-bind:status="answer.status"
          v-bind:notes="answer.notes"
          v-bind:textbox_text="answer.textbox_text"
          :allowAddNotes="allowAddNotes"
          :allowStatusChange="allowStatusChange"
          :showSolution="showSolutions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import SimpleAnswer from "../components/SimpleAnswer";
import UserTile from "../components/UserTile";
import ResultsSummary from "../components/ResultsSummary";
export default {
  props: {
    allowAddNotes: {
      type: Boolean,
      default: false,
      description: "allow user to add notes?",
    },
    allowStatusChange: {
      type: Boolean,
      default: false,
      description: "allow user to change an answer's status?",
    },
    showSolutions: {
      type: Boolean,
      default: false,
      description: "show solutions alongside the text?",
    },
    showSummary: {
      type: Boolean,
      default: false,
      description: "show the summary panel?",
    },
  },
  components: {
    SimpleAnswer,
    UserTile,
    ResultsSummary,
  },
  data() {
    return {
      fullData: null,
    };
  },
  created() {
    const id = this.$route.params.id;
    axios.get("/api/userassessment/results/" + id).then((res) => {
      if (res.data) {
        if (!res.data.complete_date) {
          this.$swal.fire({
            position: "top",
            title: "This page is only available for completed assessments.",
            toast: true,
            icon: "error",
            showConfirmButton: true,
            timer: 5000,
          });
          this.$router.replace("/dashboard");
          return;
        }
        this.fullData = res.data;
      }
    });
  },
  computed: {
    answers() {
      if (!this.fullData) {
        return [];
      }
      return this.fullData.answers.map((answer) => ({
        id: answer.id,
        question: answer.assessment_question.question,
        answer: answer.assessment_answer.value,
        severity: answer.assessment_answer.severity.toString(),
        solution: answer.assessment_answer.solution,
        status: answer.state,
        notes: answer.notes,
        textbox_text: answer.textbox_text,
      }));
    },
  },
};
</script>