// axios error handling
import interceptors from "./interceptors";
interceptors(store);

//import Assessments from './Assessments.vue'
import { store } from "./store";

// Sweet-Alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(ArgonDashboard);
appInstance.use(VueSweetalert2);
appInstance.mount("#app");
