<template>
  <div class="overall">
    <div class="container">
      <div class="question-title">
        <h2>{{ question }}</h2>
      </div>
      <div class="content-container">
        <!-- <div class="media-container">
          <div
            class="image"
            v-if="img"
            :style="{ backgroundImage: 'url(' + imgLink + ')' }"
          ></div>
        </div> -->
        <div class="content-desc" v-if="description" v-html="description"></div>
        <!-- <textarea v-if="value.type == 'text'" class="answer-text" /> -->
        <div class="check-container" v-if="this.value.type == 'check'">
          <input class="option check-option" type="checkbox" @change="setCheck($event)" />
          {{ "I confirm the above statement." }}
        </div>
        <!-- Textbox questions -->
        <form v-if="getSelectMenuType === 'textbox'">
          <div style="display: flex; flex-direction: column">
            <div>
              <textarea name="quiz-options" rows="5" cols="50" class="options" :id="'textbox-input'"
                @input="onChange($event, textboxInput)" v-model="textboxInput"
                :data-identifier="JSON.stringify(options[0])">
              </textarea>
              <!-- <div class="content-options" v-for="(option, id) in options" :key="id">
                <input type="radio" v-bind:value="JSON.stringify(option)" name="quiz-options" @change="
  onChange($event, textboxInput);
displayAnswerMedia($event);
highlightTextboxInput('textbox-input');
                " class="options" :id="option.id" />
                <label :for="option.id">{{ option.value }}</label>
              </div> -->
            </div>
          </div>
        </form>
        <!-- Other types of questions -->
        <form v-else>
          <div class="content-options" v-for="(option, id) in options" :key="id">
            <div>
              <input :type="getSelectMenuType" v-bind:value="JSON.stringify(option)" name="quiz-options" @change="
  onChange($event);
displayAnswerMedia($event);
              " class="options" :id="option.id" />
              <label :for="option.id">{{ option.value }}</label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Display media on certain selected answers-->
    <div class="answer-media">
      <div class="displayTextOnAnswer" v-if="answerDisplayedText">
        <p>{{ answerDisplayedText }}</p>
      </div>
      <div class="displayedImageContainer" v-if="answerDisplayedPhoto">
        <img :src="answerDisplayedPhoto" />
      </div>
      <div class="mediaContainer" v-if="answerDisplayedVideo">
        <video class="pageVideo" style="width: 100%" crossorigin="anonymous" controls>
          <source :src="answerDisplayedVideo" type="video/mp4" />
          <track v-if="videoEnglishSubtitles" :src="videoEnglishSubtitles" kind="subtitles" srclang="en"
            label="English" />
          <track v-if="videoPolishSubtitles" :src="videoPolishSubtitles" kind="subtitles" srclang="pl" label="Polish" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "onChange", "setCheck"],
  data() {
    return {
      question: null,
      description: "",
      options: [],
      answerDisplayedText: null,
      answerDisplayedPhoto: null,
      answerDisplayedVideo: null,
      videoEnglishSubtitles: null,
      videoPolishSubtitles: null,
      textboxInput: null,
    };
  },
  created() {
    this.initNewQuestion();
  },
  watch: {
    value() {
      this.clearSelection();
      this.clearDisplayedMedia();
      this.initNewQuestion();
    },
  },
  computed: {
    getSelectMenuType() {
      switch (this.value.type) {
        case "choice":
          return "radio";
        case "multiple":
          return "checkbox";
        case "textbox":
          return "textbox";
        case "choose_correct":
          return "radio";
        default:
          return null;
      }
    },
  },
  methods: {
    initNewQuestion() {
      if (!this.value) {
        return;
      }
      this.question = this.value.question;
      this.description = this.value.description;
      this.options = this.value.answers;
    },
    displayAnswerMedia(event) {
      const data = JSON.parse(event.target.value);
      if (data.display_text) {
        this.answerDisplayedText = data.display_text;
      } else {
        this.answerDisplayedText = null;
      }
      if (data.display_media) {
        if (data.media_type === "photo") {
          this.answerDisplayedPhoto = data.display_media;
        } else if (data.media_type === "video") {
          this.answerDisplayedVideo = data.display_media;
          this.videoEnglishSubtitles = data.english_subtitle;
          this.videoPolishSubtitles = data.polish_subtitle;
        }
      } else {
        this.answerDisplayedPhoto = null;
        this.answerDisplayedVideo = null;
        this.videoEnglishSubtitles = null;
        this.videoPolishSubtitles = null;
      }
    },
    clearDisplayedMedia() {
      this.answerDisplayedText = null;
      this.answerDisplayedPhoto = null;
      this.answerDisplayedVideo = null;
    },
    clearSelection() {
      for (let i = 0; i < this.options.length; i++) {
        const el = document.getElementById(this.options[i].id);
        if (el) {
          el.checked = false;
        }
      }
    },
    disselectRadioOptions() {
      var i;
      for (i = 0; i < this.options.length; i++) {
        document.getElementById(this.options[i].id).checked = false;
      }
      this.unhighlightTextboxInput("textbox-input");
    },
    highlightTextboxInput(textboxId) {
      document.getElementById(textboxId).style.borderColor = "green";
    },
    unhighlightTextboxInput(textboxId) {
      document.getElementById(textboxId).style.borderColor = "grey";
    },
  },
};
</script>

<style scoped>
.overall {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}

.progress-bar {
  margin: 0;
}

.container {
  /* width: fit-content; */
  /* min-width: 30vw; */
  /* width: 80%;  */
  border-radius: 5px;
  box-shadow: 0px 0px 12px -3.5px rgba(0, 0, 0, 0.55);
}

.content-container {
  box-sizing: border-box;
  padding: 1.3rem 1.5rem;
  line-height: 1.2;
}

.content-desc {
  width: 100%;
  margin-bottom: 0;
  color: rgb(31, 31, 31);
  font-size: 1rem;
  text-align: justify;
}

/* deep selector for vue scoped styles */
/* https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors */
.content-desc:deep(strong) {
  font-weight: bold;
}

.question-title>h2 {
  font-size: 1.25em;
  margin: 0;
  word-wrap: break-word;
  padding: 0;
  box-sizing: border-box;
  padding: 0.6rem 1.5rem 0.1rem 1.5rem;
}

.question-title {
  /* margin-bottom: 2rem; */
  border-bottom: #dadada 1.5px solid;
}

.content-img>.image {
  width: 100%;
  height: 30vh;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.content-img {
  margin-bottom: 0.8rem;
}

form {
  padding: 0px;
  margin: 0px;
}

.options {
  margin: 0px 5px 0px 0px;
  padding: 0px;
}

.answer-text {
  box-sizing: border-box;
  border: 1px solid rgb(197, 197, 197);
  box-shadow: 0px 0px 7px -3.5px rgba(0, 0, 0, 0.55);
  border-radius: 5px;
  width: 100%;
  resize: none;
  outline: none;
  font-size: 1.2rem;
  height: 10vh;
  font-family: inherit;
  padding: 5px;
}

.answer-media {
  margin-top: 20px;
}

.mediaContainer {
  align-self: center;
  border-radius: 5px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.55);
  margin-bottom: 20px;
}

.pageVideo {
  border-radius: 5px;
  display: block;
  /* object-fit: contain; */
  height: 100%;
  width: 50vw;
  box-shadow: 0px 0px 12px -3.5px rgba(0, 0, 0, 0.55);
}

.displayedImageContainer {
  align-self: center;
  border-radius: 5px;
  height: 100%;
  margin-bottom: 20px;
}

.displayedImageContainer>img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 12px -3.5px rgba(0, 0, 0, 0.55);
}

.displayTextOnAnswer {
  align-self: center;
  display: flex;
  justify-content: left;
  white-space: pre-line;
}

#textbox-input {
  resize: none;
}
</style>
