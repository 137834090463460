<template>
  <div class="card">
    <div class="card-body">
      <Spinner v-if="isLoading" />
      <DropdownButton
        @click="searchdropdown = !searchdropdown"
        v-if="!isLoading"
      >{{ selectedItem?.name || "Filter by Assessment Type" }}</DropdownButton>

      <search-dropdown
        v-if="!isLoading && searchdropdown"
        :items="itemList"
        placeholder="Search assessments..."
        class="searchDropdown"
        :handleSelect="getUsersFromAssessment"
      />

      <p class="mt-4 small" v-if="!isLoading && selectedItem">Click a row to review the assessment</p>

      <DataTable
        class="mt-4"
        :data="rows"
        :columns="columns"
        v-if="!isLoading && selectedItem"
        :filters="tableFilters"
        :order="[2, 'desc']"
        :clickable="true"
        :paginate="true"
        :pageLength="50"
        :searchable="true"
        @rowClicked="navigate"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DropdownButton from "../Buttons/DropdownButton.vue";
import SearchDropdown from "../SearchDropdown";
export default {
  name: "AssessmentsTable",
  props: {
    source: String,
  },
  data() {
    const isThriveAdmin = this.$store.state.user.isThriveAdmin;
    const isDepartmentAdmin = this.$store.state.user.isDepartmentAdmin;

    return {
      selectedItem: null,
      searchdropdown: true,
      itemList: [],
      rows: [],
      isLoading: false,
      columns: [
        { data: "assessment_id", visible: false }, // required for filtering
        { data: "id", title: "id", className: "text-center", visible: isThriveAdmin },
        { data: ({ complete_date, complete_date_machine }, purpose) => purpose === "sort" ? complete_date_machine : complete_date, title: "Completed" },
        { data: "user.name", title: "Name" },
        { data: "user.email", title: "Email" },
        {
          data: "user.company.name",
          title: "Company",
          visible: isThriveAdmin,
        },
        { data: "user.department.name", title: "Department", visible: !isDepartmentAdmin },
        { data: "severity_data.severity", title: "Severity" },
        { data: "severity_data.unresolved", title: "Unresolved", className: "text-center" },
      ],
    };
  },
  components: {
    DropdownButton,
    SearchDropdown,
  },
  computed: {
    tableFilters() {
      if (!this.selectedItem) {
        return [];
      }
      return [[0, this.selectedItem.id]];
    }
  },
  created() {
    this.isLoading = true;
    axios.get("/api/assessments").then((res) => {
      this.itemList = res.data;
      axios.get("/api/assessments/users/").then((res) => {
        this.rows = res.data;
        this.isLoading = false;
      });
    });
  },
  methods: {
    getUsersFromAssessment(item) {
      this.searchdropdown = false;
      this.selectedItem = item;
    },
    navigate({ id }) {
      this.$router.push(`/ua/${id}/review`);
    },
  },
};
</script>
<style>
.searchDropdown {
  position: absolute;
}
</style>
