<template>
  <modal
    v-model:show="isVisible"
    :isStatic="true"
    @close="close"
    modalClasses="modal-lg"
  >
    <h3>Assign Assessment</h3>
    <div class="info-list"><strong>Users:</strong> {{ userNames }}</div>
    <div class="text-danger mt-4" v-if="!loadingCredits && !hasCredits">
      <p>
        <strong>
          You don't have sufficient license credits available.<br />You need
          {{ userIds.length }} credit(s) and have
          {{ availableCredits }}.</strong
        >
      </p>
      <base-button type="primary" @click="buyMore"
        >Buy More Credits</base-button
      >
      <base-button type="secondary" @click="close">Cancel</base-button>
    </div>
    <div v-if="!loadingCredits && hasCredits">
      <div class="text-success mt-4">
        <p>
          <strong>
            Note: This assignment will use up to {{ userIds.length }} of your
            {{ availableCredits }} available license credits.</strong
          >
        </p>
      </div>
      <div class="form-group mt-4">
        <p><strong>Assessment Type</strong></p>
        <assessment-selector
          v-model="selectedAssessmentId"
          :disabled="isAssigning || assignResults"
        />
      </div>
      <div class="form-group" v-if="!isAssigning && !assignResults">
        <base-button
          type="primary"
          @click="assignAssessments"
          :disabled="!canSubmit"
          >Assign</base-button
        >
        <base-button type="secondary" @click="close">Cancel</base-button>
      </div>
      <Spinner v-if="isAssigning" />
      <!-- results view -->
      <div v-if="!isAssigning && assignResults">
        <p class="text-success"><strong>Done!</strong></p>
        <p v-if="assignResults.assigned > 0">
          {{ assignResults.assigned }} credit(s) were used. You have
          {{ assignResults.remainingCredits }} credit(s) left.
        </p>
        <p v-if="assignResults.assigned == 0">No credits were used.</p>
        <div v-if="assignResults.notAssigned > 0">
          <p>These users were not assigned:</p>
          <p
            v-for="res in assignResults.results.filter(
              (el) => !el.canAssign || !el.didAssign
            )"
            :key="res.id"
          >
            <strong>{{ res.name }}:</strong> {{ res.result }}
          </p>
        </div>
        <base-button type="primary" @click="closeFinal">Ok</base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import AssessmentSelector from "../AssessmentSelector.vue";
export default {
  components: {
    AssessmentSelector,
  },
  data() {
    return {
      isVisible: false,
      userNames: "",
      userIds: [],
      selectedAssessmentId: -1,
      isAssigning: false,
      assignResults: null,
      loadingCredits: false,
      availableCredits: -1, // -1 means we don't know
    };
  },
  computed: {
    hasCredits() {
      return this.availableCredits >= this.userIds.length;
    },
    hasAssessmentType() {
      return this.selectedAssessmentId > 0;
    },
    canSubmit() {
      return this.hasCredits && this.hasAssessmentType && !this.isAssigning;
    },
  },
  methods: {
    show(selectedUsers) {
      const users = [...selectedUsers];
      if (users.length === 0) {
        this.$swal.fire({
          position: "top-end",
          title: "Please select users first.",
          toast: true,
          icon: "error",
          showConfirmButton: true,
          timer: 5000,
        });
        return;
      }
      this.userNames = users.reduce(
        (str, { name }) => (str ? `${str}, ${name}` : name),
        ""
      );
      this.userIds = users.map((el) => el.id);
      this.isVisible = true;

      this.loadingCredits = true;
      const currentUser = this.$store.state.user;
      axios
        .get(`/api/companies/credits/${currentUser.company.id}`)
        .then((res) => {
          this.availableCredits = res.data.credits;
          this.loadingCredits = false;
        });
    },
    close() {
      this.isVisible = false;
      this.userNames = "";
      this.userIds = [];
      this.selectedAssessmentId = -1;
      this.isAssigning = false;
      this.assignResults = null;
      this.$emit("close");
    },
    closeFinal() {
      this.close();
      this.$router.go(); // refresh page
    },
    buyMore() {
      this.$router.push({ name: "licenses" });
    },
    assignAssessments() {
      if (this.loadingCredits || !this.hasCredits || !this.hasAssessmentType) {
        return;
      }
      const url = `/api/assessments/assign`;
      const payload = {
        users: [...this.userIds],
        assessment_id: this.selectedAssessmentId,
      };
      this.isAssigning = true;
      axios.post(url, payload).then((res) => {
        this.isAssigning = false;
        this.assignResults = res.data;
      });
    },
  },
};
</script>