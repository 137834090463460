<template>
  <div class="container-fluid pt-5 pt-md-8">
    <div class="card">
      <div class="card-body">
        <Spinner v-if="!hasAllData"/>
        <Questions
          v-if="hasAllData"
          :data="fullData"
          :initialPage="initialPage"
        ></Questions>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Questions from "../components/Questions.vue";
export default {
  components: {
    Questions,
  },
  data() {
    return {
      fullData: null,
      initialPage: -1,
    };
  },
  computed: {
    hasAllData() {
      return this.fullData && this.initialPage >= 0;
    },
  },
  created() {
    const uaId = this.$route.params.id;

    axios.get("/api/userassessment/pages/" + uaId).then((res) => {
      if (res.data) {
        if (res.data.complete_date) {
          this.$swal.fire({
            position: "top",
            title: "This assessment is already complete.\nHere are the results.",
            toast: true,
            icon: "error",
            showConfirmButton: true,
            timer: 5000,
          });
          this.$router.replace(`/ua/${uaId}/results`);
          return;
        }
        this.fullData = res.data;

        // load current page number
        axios.get("/api/userassessment/pagenum/" + uaId).then((res) => {
          this.initialPage = res.data.page;
        });
      }
    });
  },
};
</script>

<style>
</style>
