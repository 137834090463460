<template>
  <div class="container-fluid pt-5 pt-md-8">
    <div v-if="user.isEndUser">
      <UserTile2 v-bind="user"></UserTile2>
      <QuizTable class="mt-4" />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-3">
          <stat-tile
            title="Total Users"
            statInfo="Total number of users signed up to the platform in the last 5 weeks."
            :value="users"
          ></stat-tile>
          <stat-tile
            title="Assessments Due"
            statInfo="Total number of assessments that need to be completed in the last 5 weeks."
            :value="assessments"
            class="mt-4"
          ></stat-tile>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <stat-tile
            title="Risks"
            statInfo="Total number of distinct risks in the last 5 weeks."
            :value="risks"
          ></stat-tile>
          <stat-tile
            title="Unresolved"
            statInfo="Total number of risks that are still unresolved in the last 5 weeks."
            :value="unresolved"
            class="mt-4"
          ></stat-tile>
        </div>
        <div class="col-lg-6 mt-4 mt-lg-0">
          <UserTile2 v-bind="user"></UserTile2>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <QuizTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserTile2 from "../components/UserTile2.vue";
import axios from "axios";
import QuizTable from "../components/SortableTables/QuizTable";
import StatTile from "../components/StatTile";
export default {
  components: {
    UserTile2,
    QuizTable,
    StatTile,
  },
  data() {
    return {
      users: "",
      assessments: "",
      risks: "",
      unresolved: "",
      role: "",
      name: "",
      email: "",
      company: "",
      department: "",
      creation: "",
      initials: "",
      emailNotification: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    if (this.user && !this.user.isEndUser) {
      axios.get("/api/users/mystats").then((res) => {
        this.users = "" + res.data.statistics.users.count;
        this.assessments = "" + res.data.statistics.assessments.count;
        this.risks = "" + res.data.statistics.risks.count;
        this.unresolved = "" + res.data.statistics.unresolved.count;
      });
    }
  },
};
</script>
<style scoped>
</style>