<template>
    <div class="container-fluid pt-5 pt-md-8">
        <div class="action-btns">

        </div>
        <div class="card p-2" v-if="isLoading">
            <Spinner />
        </div>
        <div class="card p-2" v-if="!isLoading && this.rowData.length">
            <DataTable class="mt-4" v-if="!isLoading && rowData.length > 0" ref="dataTable" :data="rowData"
                :columns="columns" :searchable="true" noDataText="No reminder emails are due.">
            </DataTable>
            <p>
                <base-button class="primary mb-5" @click="sendAll">Send All</base-button>
            </p>
        </div>
        <div class="card p-2" v-if="!isLoading && !this.rowData.length">No reminder emails are due.</div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            isLoading: true,
            rowData: [],
            columns: [{ data: "due_date", title: "Due Date" }, { data: "name", title: "Name" }, { data: "email", title: "Email" }, { data: "company_name", title: "Company" }, { data: "assessment_name", title: "Assessment" }],
        };
    },
    created() {
        this.listReminderEmails();
    },
    methods: {
        listReminderEmails() {
            this.isLoading = true;
            this.rowData = [];
            this.columns = [{ data: "due_date", title: "Due Date" }, { data: "name", title: "Name" }, { data: "email", title: "Email" }, { data: "company_name", title: "Company" }, { data: "assessment_name", title: "Assessment" }];
            this.textOutput = '';
            axios.get("/api/admin/reminder-emails").then((res) => {
                this.isLoading = false;
                this.rowData = res.data;
            });
        },
        sendAll() {
            if (!this.rowData.length) {
                return;
            }
            this.$swal.fire({
                title: `Are you sure you want to send ${this.rowData.length} email(s)?`,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Yes, go ahead`,
                denyButtonText: `No, cancel`,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        axios.post("/api/admin/reminder-emails").then((res) => {
                            this.isLoading = false;
                            this.$swal.fire({
                                position: "top",
                                title: `A total of ${res.data.sent} email(s) have been sent`,
                                toast: true,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3500,
                            });
                            this.listReminderEmails();
                        });
                    }
                })
        }
    }
}
</script>
