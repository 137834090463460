<template>
  <modal v-model:show="isVisible" @close="close">
    <h3>Create Company</h3>
    <form @submit="createCompany">
      <base-input type="text" v-model="companyName" placeholder="Name.." />
      <base-input type="submit" class="btn-primary" />
    </form>
    <div class="text-center">
      <base-button type="secondary" @click="close">Cancel</base-button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";

export default {
  props: ["show"],
  data() {
    return {
      isVisible: false,
      companyName: "",
    };
  },
  mounted() {
    this.isVisible = this.show;
  },
  watch: {
    show: function (newVal) {
      this.isVisible = newVal;
      this.companyName = "";
    },
  },
  methods: {
    createCompany() {
      axios
        .post("/api/companies", {
          name: this.companyName,
        })
        .then((res) => {
          if (res.status === 201) {
            location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    close() {
      this.isVisible = false;
      this.companyName = "";
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>