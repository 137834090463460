<template>
  <div class="card">
    <div class="card-body">
      <h3>Your Assessments</h3>
      <p v-if="!hasAssessments">You have no assessments available.</p>
      <div class="table-responsive mt-4">
        <table class="table table-striped" width="100%">
          <tbody>
            <tr v-if="pendingData.length || licenseData.length">
              <td colspan="3" class="td-title">Available Assessments</td>
            </tr>
            <tr v-for="row in pendingData" :key="`p-${row.id}`">
              <td>{{ row.name }}</td>
              <td>Due {{ row.due_date }}</td>
              <td>
                <base-button
                  type="primary"
                  size="sm"
                  @click="continueAssessment(row)"
                  v-if="!row.has_started"
                  >Start</base-button
                >
                <base-button
                  type="primary"
                  size="sm"
                  @click="continueAssessment(row)"
                  v-if="row.has_started"
                  >Continue</base-button
                ><base-button
                  type="warning"
                  size="sm"
                  @click="restartAssessment(row)"
                  v-if="row.has_started"
                  >Restart</base-button
                >
              </td>
            </tr>
            <tr v-for="row in licenseData" :key="`l-${row.id}`">
              <td>{{ row.assessment_name }}</td>
              <td>&nbsp;</td>
              <td>
                <base-button
                  type="primary"
                  size="sm"
                  @click="startNewAssessment(row)"
                  >Retake</base-button
                >
              </td>
            </tr>
            <tr>
              <td colspan="3" class="td-title" v-if="completedData.length">
                Previously Completed Assessments
              </td>
            </tr>
            <tr v-for="row in completedData" :key="`c-${row.id}`">
              <td>{{ row.name }}</td>
              <td>Completed {{ row.complete_date }}</td>
              <td>
                <base-button
                  type="secondary"
                  size="sm"
                  @click="viewResults(row)"
                  >View Results</base-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { assessmentStates } from "../../helpers/constants";
export default {
  name: "QuizTable",
  data() {
    return {
      fullData: null,
    };
  },
  computed: {
    pendingData() {
      if (!this.fullData) {
        return [];
      }
      return this.fullData.assessments.filter(
        (el) => el.state === assessmentStates.PENDING
      );
    },
    licenseData() {
      if (!this.fullData) {
        return [];
      }
      return this.fullData.licenses.filter((el) => !el.has_pending);
    },
    completedData() {
      if (!this.fullData) {
        return [];
      }
      return this.fullData.assessments.filter(
        (el) => el.state === assessmentStates.COMPLETED
      );
    },
    hasAssessments() {
      return this.pendingData.length || this.licenseData.length || this.completedData.length;
    }
  },
  created() {
    axios.get("/api/users/me").then((res) => {
      this.fullData = res.data;
    });
  },
  methods: {
    continueAssessment(userAssessment) {
      if (userAssessment.state !== assessmentStates.PENDING) {
        return;
      }
      this.launchAssessment(userAssessment.id);
    },
    restartAssessment(userAssessment) {
      if (
        !(
          userAssessment.state === assessmentStates.PENDING &&
          userAssessment.has_started
        )
      ) {
        return;
      }
      this.$swal
        .fire({
          title:
            "Restarting will delete all your previous answers for this assessment. Are you sure?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, restart",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(`/api/userassessment/reset/${userAssessment.id}`)
              .then((res) => {
                if (res?.data.status === "ok") {
                  this.launchAssessment(userAssessment.id);
                } else {
                  console.error("Failed to reset assessment", res.data);
                }
              });
          }
        });
    },
    startNewAssessment(assessment) {
      axios
        .post("/api/assessments/new", {
          assessment_id: assessment.assessment_id,
        })
        .then((res) => {
          if (res.data?.status == "ok" && res.data?.assessment_id) {
            this.launchAssessment(res.data.assessment_id);
          } else {
            console.error("Error starting new assessment", res.data);
          }
        });
    },
    viewResults(userAssessment) {
      this.launchAssessment(userAssessment.id, "results");
    },
    launchAssessment(userAssessmentId, action = "") {
      let url = `/ua/${userAssessmentId}`;
      if (action) {
        url += `/${action}`;
      }
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
.td-title {
  font-weight: bold;
  font-size: 105%;
  background: #cecece;
}
</style>