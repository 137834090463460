<template>
  <div>
    <div class="row">
      <div class="col">
        <p v-if="searchable" class="form-inline">
          <label class="mr-2">Search:</label>
          <input v-model="globalFilter" class="form-control form-control-sm" />
        </p>
      </div>
      <div class="col text-right">
        <base-button type="primary" size="sm" @click="exportCSV" v-if="exportable">Export to Excel</base-button>
      </div>
    </div>
    <div class="table-container mt-4" ref="tableContainer">
      <table :class="`table table-striped table-bordered ${extraTableStyles}`" style="table-layout:fixed" width="100px"
        ref="theTable">
        <thead>
          <tr>
            <th v-for="col, i in visColumns" :key="`th-${col.realIndex}`"
              @click="col.orderable === false ? null : thClick(i)"
              :style="`width: ${colWidths.length ? colWidths[i] : 0}px`">
              {{ col.title }}
              <span v-if="col.orderable !== false" :class="`sortinfo ${sortDir(col.realIndex)}`">
                <ArrowUp class="up" />
                <ArrowDown class="down" />
                <ArrowSwitch class="switch" />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in pageData" :key="`tr-${row.rowIndex}`"
            @click="clickable && !selectable ? trClick(row) : null" :className="`${row.selected ? 'selected' : ''}`">
            <td v-for="c, j in visColumns" :key="`td-${row.rowIndex}-${j}`"
              :style="`width: ${colWidths.length ? colWidths[j] : 200}px`" :class="`${c.className || ''}`"
              @click="selectable && !clickable && c.realIndex === -1 ? rowSelect(row) : null">
              <span v-if="c.realIndex === -1">
                <Check v-if="row.selected"></Check>
                <Box v-if="!row.selected"></Box>
              </span>
              <span v-else>{{ c.defaultValue || row.formattedValues[c.realIndex] }}</span>
            </td>
          </tr>
          <tr class="no-data text-center" v-if="pageData.length === 0">
            <td :colspan="visColumns.length">{{ noDataText }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-4">
      <div class="col">
        <p class="small" v-if="recordCount > 0">
          Showing {{ pageStart + 1 }} to {{ pageEnd }} of {{ recordCount }} entries
          <span v-if="selectable && selectedIndices.length">({{ selectedIndices.length }} selected)</span>
        </p>
      </div>
      <div class="col text-right">
        <p v-if="paginate" class="small">
          <base-button @click="goPrevious" :disabled="!hasPrevious" size="sm">&lt;&lt;</base-button>
          <span class="d-inline-block ml-2 mr-3">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
          <base-button @click="goNext" :disabled="!hasNext" size="sm">&gt;&gt;</base-button>
        </p>
      </div>
    </div>

    <div class="small" v-if="paginate && pagelengthable">
      Show
      <select class="form-input" @change="onPageLengthChange" v-model="selectedPageLength">
        <option v-for="l in pageLengthOptions" :key="l" :value="l">{{ l }}</option>
      </select> per page
    </div>
  </div>
</template>

<script>
import papaparse from "papaparse"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import ArrowUp from "@/components/icons/ArrowUp.vue"
import ArrowSwitch from "@/components/icons/ArrowSwitch.vue"
import Check from "@/components/icons/Check.vue"
import Box from "@/components/icons/Box.vue"
export default {
  components: {
    ArrowDown,
    ArrowUp,
    ArrowSwitch,
    Check,
    Box,
  },
  props: {
    data: Array,
    columns: Array,
    assessmentId: Number,
    order: {
      type: Array,
      default: null,
    },
    filters: {
      type: Array, // [columnIndex, filterValue] - TODO check columnIndex is in range
      default: () => [],
    },
    searchable: Boolean,
    selectable: {
      type: Boolean,
      default: false,
      description: "can rows be selected? mutually exclusive with clickable",
    },
    clickable: {
      type: Boolean,
      default: false,
      description: "can a single row be clicked on? mutually exclusive with selectable",
    },
    paginate: {
      type: Boolean,
      default: false,
      description: "show pages of items"
    },
    pagelengthable: {
      type: Boolean,
      default: false,
      description: "shows a drop down to select how many items per page. requires paginate==true"
    },
    pageLength: {
      type: Number,
      default: 20,
      description: "initial items per page, works with paginate"
    },
    exportable: {
      type: Boolean,
      default: false,
      description: "Provides a button to export the data as a CSV",
    },
    exportFilename: {
      type: String,
      default: "data-download.csv",
      description: "Filename to use for data downloads",
    },
    noDataText: {
      type: String,
      default: "No data available",
    }
  },
  data() {
    const pageLengthOptions = [10, 20, 50, 100];

    return {
      flatRowData: [], // flattened row data, { rowIndex, selected, lowerValues: [], sortValues: [], formattedValues: [], originalData }
      visColumns: [],
      table: null,
      sort: [2, "asc"],
      currentPage: 0,
      currentPageLength: this.pageLength,
      currentOrder: this.order ? [...this.order] : null,
      colWidths: [],
      globalFilter: "",
      selectedIndices: [],
      pageLengthOptions: pageLengthOptions,
      selectedPageLength: this.pageLength || pageLengthOptions[0],
    };
  },
  created() {
    // row data prep
    this.flatRowData = this.data.map((row, rowIndex) => {
      const values = this.columns.map(c => this.cellData(c, row));

      const o = {
        rowIndex,
        selected: false,
        originalData: row,
      };

      // values for filtering
      o.lowerValues = values.map(el => el.toLowerCase());

      // values for sorting (allows for bespoke sort data per column)
      o.sortValues = this.columns.map((c, index) => {
        const sortValue = this.sortData(c, row);
        if (sortValue !== null) {
          return sortValue;
        }
        return o.lowerValues[index]; // default to lower-case string values
      });

      // values for display
      o.formattedValues = values.map((value, index) => {
        const col = this.columns[index];
        if (col.formatter) {
          return col.formatter(value);
        } else {
          return value;
        }
      })
      return o;
    });

    // visible columns
    const visCols = this.columns.map((col, realIndex) => ({ ...col, realIndex, title: col.title || " ", sortDir: "" })).filter(c => c.visible !== false);

    // insert column for the selector checkmark
    if (this.selectable) {
      visCols.unshift({ title: '', searchable: false, orderable: false, realIndex: -1, className: "select text-center" });
    }
    this.visColumns = visCols;

    if (this.currentOrder && this.currentOrder[0] >= this.visColumns.length) {
      this.currentOrder = [0, "asc"];
    }

    // page length options
    if (this.paginate && this.pagelengthable) {
      const list = [...this.pageLengthOptions];
      if (!list.includes(this.pageLength)) {
        list.push(this.pageLength);
        list.sort((a, b) => a - b);
      }
      this.pageLengthOptions = list;
    }
  },
  mounted() {
    this.recalcColumnWidths();
  },
  watch: {
    filters() {
      this.currentPage = 0;
      this.deselect();
    },
    globalFilter() {
      this.currentPage = 0;
      this.deselect();
    },
  },
  computed: {
    extraTableStyles() {
      if (this.clickable) {
        return "table-hover";
      }
      return "";
    },
    filteredRowData() {
      // column filters are more aggressive so we do them first
      const columnFiltered = this.filters.length === 0 ? this.flatRowData : this.flatRowData.filter((row) => {
        let isOk = true;
        for (let i = 0; i < this.filters.length; i++) {
          const [colIndex, value] = this.filters[i];
          const column = this.columns[colIndex];
          if (column.searchable === false) {
            break; // can't filter this column
          }

          const rowValue = row.lowerValues[colIndex];

          if (typeof value === 'number' || !isNaN(value)) {
            // numeric comparison
            isOk = value == rowValue;
          } else {
            // string comparison
            const lowerValue = value.toLowerCase();
            isOk = rowValue.includes(lowerValue);
          }

          // early exit if there is a mismatch
          if (!isOk) {
            return false;
          }
        }
        return isOk;
      });

      if (!this.globalFilter || this.globalFilter.length < 3) {
        return columnFiltered;
      }

      // now filter by global search terms
      // only search 'searchable' and visible columns
      const searchIndices = this.columns.reduce((aggregate, col, index) => {
        if (col.searchable === false || col.visible === false) {
          return aggregate;
        } else {
          return [...aggregate, index];
        }
      }, []);

      const lowerFilter = this.globalFilter.toLowerCase();
      return columnFiltered.filter(row => {
        for (let i = 0; i < searchIndices.length; i++) {
          const cellData = row.lowerValues[searchIndices[i]];
          if (cellData.includes(lowerFilter)) {
            return true; // row is included if any column matches
          }
        }
        return false; // no column matched - the row is not included
      });
    },
    /** sorted, filtered data **/
    displayData() {
      if (!this.currentOrder || !this.filteredRowData.length) {
        return this.filteredRowData;
      }

      const sortColIndex = this.currentOrder[0];
      const sortDir = this.currentOrder[1];
      const sortAscending = sortDir === "asc";

      // auto-detect column type
      const firstValue = this.filteredRowData[0].sortValues[sortColIndex];

      let sortFn;

      if (!isNaN(firstValue)) {
        // numeric sort
        if (sortAscending) {
          sortFn = (a, b) => a.sortValues[sortColIndex] - b.sortValues[sortColIndex];
        } else {
          sortFn = (a, b) => b.sortValues[sortColIndex] - a.sortValues[sortColIndex];
        }
      } else {
        // string sort
        if (sortAscending) {
          sortFn = (a, b) => {
            const valueA = a.sortValues[sortColIndex];
            const valueB = b.sortValues[sortColIndex];

            return valueA < valueB ? -1 : (valueA == valueB ? 0 : 1);
          }
        } else {
          sortFn = (a, b) => {
            const valueA = a.sortValues[sortColIndex];
            const valueB = b.sortValues[sortColIndex];

            return valueA > valueB ? -1 : (valueA == valueB ? 0 : 1);
          }
        }
      }

      // do the sort
      return [...this.filteredRowData].sort(sortFn);
    },
    /** display data to show on the current page **/
    pageData() {
      return this.paginate ? this.displayData.slice(this.pageStart, this.pageEnd) : this.displayData;
    },
    longestRow() {
      // get the longest piece of data for each column
      return this.visColumns.map((el) => this.data.reduce(({ len, str }, row) => {
        const value = this.cellData(el, row);
        if (value.length > len) {
          return { len: value.length, str: value };
        }
        return { len, str }
      }, { len: 0, str: "" }).str);
    },
    recordCount() {
      return this.displayData.length;
    },
    pageStart() {
      return this.paginate ? this.currentPage * this.currentPageLength : 0;
    },
    pageEnd() {
      return this.paginate ? Math.min(this.recordCount, this.pageStart + this.currentPageLength) : this.recordCount;
    },
    totalPages() {
      return Math.ceil(this.displayData.length / this.currentPageLength);
    },
    hasNext() {
      return this.currentPage < this.totalPages - 1;
    },
    hasPrevious() {
      return this.currentPage > 0;
    }
  },
  methods: {
    /** returns cell value to display, as a string **/
    cellData(col, row) {
      let value = null;
      if (!col.data) {
        return "";
      }
      if (typeof col.data === 'function') {
        value = col.data(row, "display");
      } else {
        // treat data as a property name into the row data
        // eg data="company.name" - returns row["company"]["name"]
        value = col.data.split(".").reduce((aggregate, current) => aggregate[current], row);
      }
      if (value === "" || value === null || value === undefined) {
        value = " ";
      }
      return `${value}`; // return as string for display purposes
    },
    /** returns bespoke data used for sorting if the column defines a data function */
    sortData(col, row) {
      if (typeof col.data === 'function') {
        return col.data(row, "sort");
      }
      return null;
    },
    goPrevious() {
      this.hasPrevious && this.currentPage--;
    },
    goNext() {
      this.hasNext && this.currentPage++;
    },
    sortDir(realColIndex) {
      if (this.currentOrder && this.currentOrder[0] === realColIndex) {
        return this.currentOrder[1];
      }
      return "";
    },
    thClick(visColIndex) {
      const realIndex = this.visColumns[visColIndex].realIndex;
      if (realIndex < 0) { // fake column
        return;
      }

      const col = this.columns[realIndex];
      if (col.orderable === false) {
        return;
      }

      this.currentPage = 0;

      if (Array.isArray(this.currentOrder) && this.currentOrder.length === 2 && this.currentOrder[0] === realIndex) {
        if (this.currentOrder[1] === "asc") {
          this.currentOrder[1] = "desc";
        } else {
          this.currentOrder[1] = "asc";
        }
      } else {
        this.currentOrder = [realIndex, "asc"];
      }
      this.$emit("orderChanged", this.currentOrder);
    },
    trClick(row) {
      if (this.selectable || !this.clickable) {
        return;
      }
      this.$emit("rowClicked", row.originalData);
    },
    rowSelect(row) {
      if (this.clickable || !this.selectable) {
        return;
      }
      if (row.selected) {
        row.selected = false;
        this.selectedIndices = this.selectedIndices.filter(i => i !== row.rowIndex);
      } else {
        row.selected = true;
        this.selectedIndices.push(row.rowIndex);
      }
      this.emitSelectionChangeEvent();
    },
    onPageLengthChange() {
      this.currentPageLength = this.selectedPageLength;
      this.currentPage = 0;
      this.emitLengthChangedEvent();
    },
    recalcColumnWidths() {
      // max width cell
      const containerWidth = this.$refs.tableContainer.offsetWidth;

      // temp table with headers and longest column values
      const t = this.$refs.theTable.cloneNode()
      t.removeAttribute("id");
      t.removeAttribute("style");
      t.removeAttribute("width");
      t.style.cssText = `width: ${containerWidth}px;`; // we want the table to at least stretch to fill the container

      // use headers and standard rows
      const headerHTML = this.visColumns.reduce((html, col) => html + `<th>${col.title}</th>`, "");
      const rowHTML = this.longestRow.reduce((html, r) => html + `<td>${r}</td>`, "");

      t.insertAdjacentHTML("beforeend", `<thead><tr>${headerHTML}</tr></thead><tbody><tr>${rowHTML}</tr></thead>`);

      // container
      const container = document.createElement("div");
      container.setAttribute('style', "position: absolute; top: 0; left: 0; height: 1; right: 0; visibility: hidden");

      container.append(t);
      this.$refs.tableContainer.append(container);

      const headers = t.getElementsByTagName("TH");
      const widths = [];
      const visCols = this.visColumns;
      headers.forEach((n, i) => {
        const col = visCols[i];
        if (col.width) {
          widths.push(col.width);
        } else {
          widths.push(n.offsetWidth - 1); // TODO - correctly handle borders so we don't get a couple of pixels over
        }
      });

      // save widths
      this.colWidths = widths;

      // done
      container.remove();
    },
    deselect() {
      this.selectedIndices.forEach(i => {
        this.flatRowData[i].selected = false;
      });
      this.selectedIndices = [];
      this.emitSelectionChangeEvent();
    },
    emitLengthChangedEvent() {
      this.$emit("pageLengthChanged", this.currentPageLength);
    },
    emitSelectionChangeEvent() {
      const rowData = this.selectedIndices.map(i => this.flatRowData[i].originalData);
      this.$emit("selectionChanged", rowData);
    },
    exportCSV() {
      const titles = this.visColumns.filter(({ realIndex }) => realIndex >= 0).map(({ title }) => title);

      const data = this.displayData.map(({ formattedValues }) => {
        return formattedValues.reduce((obj, value, index) => ({ ...obj, [titles[index]]: value }), {});
      });

      const csvString = papaparse.unparse(data);
      this.triggerDownload(csvString, "text/csv", this.exportFilename);
    },
    triggerDownload(content, mimeType, filename) {
      {
        const a = document.createElement("a"); // Create "a" element
        const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
        const url = URL.createObjectURL(blob); // Create an object URL from blob
        a.setAttribute("href", url); // Set "a" element link
        a.setAttribute("download", filename); // Set download filename
        a.click(); // Start downloading
      }
    }
  },
};
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

th {
  position: relative;
}

.sortinfo {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 5px;
}

.sortinfo svg {
  fill: #cacaca;
  width: 14px;
  height: 14px;
}

.sortinfo svg.up,
.sortinfo svg.down {
  display: none;
}

.sortinfo.desc svg.down,
.sortinfo.asc svg.up {
  display: block;
  fill: #111;
}

.sortinfo.desc svg.up,
.sortinfo.desc svg.switch {
  display: none;
}

.sortinfo.asc svg.down,
.sortinfo.asc svg.switch {
  display: none;
}

table.table-hover tbody tr {
  cursor: pointer;
}

tr.selected {
  background: #ebf6f4 !important;
}

td.select {
  cursor: pointer;
}

td.select span {
  display: inline-block;
  margin-left: -8px;
  /* half the svg width */
}

td.select svg {
  width: 16px;
  height: 16px;
}
</style>

