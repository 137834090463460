import axios from "axios";
import errors from "./errHandler";
import { createStore } from "vuex";
import { roles } from "../helpers/constants";

export const store = createStore({
  state() {
    return {
      isInitialised: false,
      user: null,
      showHelpModal: false,
    };
  },
  mutations: {
    setIsInitialised(state, value) {
      state.isInitialised = value;
    },
    setUser(state, user = null) {
      if (!user) {
        state.user = null;
        return;
      }
      state.user = {
        ...user,
        isThriveAdmin: user.role === roles.THRIVE_ADMIN,
        isCompanyAdmin: user.role === roles.COMPANY_ADMIN,
        isDepartmentAdmin: user.role === roles.DEPARTMENT_ADMIN,
        isEndUser: user.role === roles.END_USER,
      };
    },
    setUserNotifications(state, value) {
      if (!state.user) {
        return;
      }
      state.user = {
        ...state.user,
        email_notifications: value,
      };
    },
    setUserLocation(state, {location, location_note}) {
      if(!state.user) {
        return;
      }
      state.user = {
        ...state.user,
        location,
        location_note,
      }
    },
    logout(state) {
      state.user = null;
    },
    showHelpModal(state) {
      state.showHelpModal = true;
    },
    hideHelpModal(state) {
      state.showHelpModal = false;
    },
  },
  actions: {
    async init({ commit }) {
      try {
        const user = await axios
          .create()
          .get("/api/users/basicme")
          .then((res) => res.data.user);
        commit("setUser", user);
      } catch (e) {
        commit("logout");
      }
      commit("setIsInitialised", true);
    },
    async login({ commit }, { email, password }) {
      try {
        const { status, user } = await axios
          .create()
          .post("/api/login", {
            email,
            password,
          })
          .then((res) => res.data);
        if (status === "ok" && user) {
          commit("setUser", user);
        } else {
          throw "Login failed";
        }
      } catch (e) {
        commit("setUser", null);
      }

      commit("setIsInitialised", true);
    },
    async logout({ commit }) {
      await axios.post("/api/logout");
      commit("setUser", null);
      commit("setIsInitialised", false);
    },
  },
  modules: {
    errors,
  },
});
