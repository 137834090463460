<template>
  <div class="question-wrapper">
    <div class="mediaContainer" v-if="page.media_type !== null">
      <img v-if="page.media_type === 'photo'" :src="page.media" class="pageImg" />
      <video v-if="page.media_type === 'video'" controls class="pageVideo" crossorigin="anonymous">
        <source :src="page.media" type="video/mp4" />
        <track :src="page.english_subtitle" kind="subtitles" srclang="en" label="English" />
        <track :src="page.polish_subtitle" kind="subtitles" srclang="pl" label="Polish" />
      </video>
    </div>

    <QuizTile v-for="(question, index) in getQuestions" v-bind:key="`${currentPage}-${index}`" :onChange="onChange"
      :assessment_id="$route.params.id" :value="question" :setCheck="setCheck"></QuizTile>
    <p v-if="incorrectAnswerSelected" id="incorrectAnswerMessage">
      Please choose the correct answer to proceed to the next question.
    </p>
    <div class="btn-row">
      <button :disabled="currentPage == 0" @click="clickPrevious()">
        Prev
      </button>
      <button :disabled="incorrectAnswerSelected" @click="clickNext()">
        {{ nextText }}
      </button>
    </div>
  </div>
</template>

<script>
import QuizTile from "../components/QuizTile.vue";

export default {
  props: ["page", "currentPage", "setAnswer", "removeAnswer", "maxPages", "next", "previous"],
  data() {
    return {
      questions: [],
      answered: {},
      check: false,
      incorrectAnswerSelected: false,
      lastMove: "previous",
    };
  },
  created() {
    this.check = false;
    this.makeQuestionObjs();
  },
  components: { QuizTile },
  watch: {
    page() {
      this.check = false;
      this.makeQuestionObjs();
    },
    getQuestions: function (newQuestions) {
      if (newQuestions.length === 0) {
        this.skipPage();
      }
    },
  },
  computed: {
    getQuestions() {
      return this.page.questions.filter((question) => {
        // If question does not need a specific answer to be displayed
        if (question.need_answer == null) {
          // set for_check to true such that this question is
          // considered for validation during page progression.
          this.setForCheck(question.id, true);
          return true;
        } else {
          // Loop through all the questions that are answered
          // and check if any of the answered questions contains
          // an answer id that corresponds to need_answer of this question.
          for (let property in this.answered) {
            if (this.answered[property] == question.need_answer) {
              // set for_check to false, so that this question will not
              // be considered for validation on page progression.
              this.setForCheck(question.id, true);
              return true;
            }
          }
          this.setForCheck(question.id, false);
          return false;
        }
      });
    },
    nextText() {
      return this.currentPage == this.maxPages - 1 ? "Finish" : "Next";
    },
  },
  methods: {
    makeQuestionObjs() {
      // making a questions objects used to track page progression
      this.questions = this.page.questions.map((question) => ({
        id: question.id,
        for_check: false,
        type: question.type,
        answers: [],
      }));
    },
    skipPage() {
      if (this.lastMove === "next") {
        this.goNext();
      } else {
        this.goPrevious();
      }
    },
    setQuestionObjs(checked, type, answer, questionId) {
      // updates the question tracking object and question answers
      switch (type) {
        case "radio":
          this.questions.forEach((question) => {
            if (question.id == questionId) {
              question.answers = [answer];
            }
          });
          break;
        case "checkbox":
          this.questions.forEach((question) => {
            if (question.id == questionId) {
              if (checked) {
                question.answers.push(answer);
              } else {
                question.answers = question.answers.filter(
                  (item) => item != answer
                );
              }
            }
          });
      }
    },
    removeQuestionObj(answer, questionId) {
      const question = this.questions.find(q => q.id === questionId);
      if (question) {
        question.answers = question.answers.filter(item => item != answer)
      }
    },
    onChange(event, optionalTextboxEntry) {
      let data;

      try {
        const isTextArea = event.target.type === 'textarea';
        data = isTextArea ? JSON.parse(event.target.dataset.identifier) : JSON.parse(event.target.value);
      } catch (e) {
        console.error('QuestionPage - failed to parse JSON', e);
        return;
      }

      switch (event.target.type) {
        case "textarea":
          if (!optionalTextboxEntry) {
            this.removeQuestionObj(data.id, data.assessment_question_id);
            this.removeAnswer({
              assessment_question: data.assessment_question_id,
              assessment_question_answer: data.id,
            });
            return;
          }
          this.setAnswer({
            assessment_question: data.assessment_question_id,
            assessment_question_answer: data.id,
            textbox_text: optionalTextboxEntry,
          });
          this.setQuestionObjs(
            true,
            "radio",
            data.id,
            data.assessment_question_id
          );
          break;
        case "radio":
          // Textbox questions with corresponding radio button have a textbox entry
          // This is sent in the post request
          if (optionalTextboxEntry) {
            this.setAnswer({
              assessment_question: data.assessment_question_id,
              assessment_question_answer: data.id,
              textbox_text: optionalTextboxEntry,
            });
            this.setQuestionObjs(
              true,
              "radio",
              data.id,
              data.assessment_question_id
            );
            // Normal radio button questions
          } else {
            this.setAnswer({
              assessment_question: data.assessment_question_id,
              assessment_question_answer: data.id,
            });
            this.setQuestionObjs(
              true,
              "radio",
              data.id,
              data.assessment_question_id
            );
          }
          break;
        case "checkbox":
          this.setAnswer({
            assessment_question: data.assessment_question_id,
            assessment_question_answer: data.id,
            remove_multiple: !event.target.checked,
          });
          this.setQuestionObjs(
            event.target.checked,
            "checkbox",
            data.id,
            data.assessment_question_id
          );
          break;
      }
      this.setFilter(data);
      // Check if answer is either correct or incorrect
      if (data.is_correct === false) {
        this.incorrectAnswerSelected = true;
      } else {
        this.incorrectAnswerSelected = false;
      }
    },
    setFilter(data) {
      // Object with question ids as keys which corresponds to the id of
      // the answer chosen as value.
      this.answered = {
        ...this.answered,
        [data.assessment_question_id]: data.id,
      };
    },
    showFeedback(msg) {
      this.$swal.fire({
        position: "top",
        title: msg,
        toast: true,
        icon: "error",
        showConfirmButton: false,
        timer: 5000,
      });
    },
    closeFeedback() {
      this.$swal.close();
    },
    clickNext() {
      /* Do not allow user to proceed to next question
       if they have selected the wrong answer */
      if (this.incorrectAnswerSelected === true) {
        this.showFeedback(
          "Please choose the correct answer before proceeding."
        );
      } else {
        if (this.page.questions[0].type == "check") {
          if (this.check == false) {
            this.showFeedback(
              "Please confirm the statement before proceeding to the next page."
            );
          } else {
            this.goNext();
          }
        } else {
          if (this.validateNext()) {
            this.showFeedback(
              "Please complete the form before proceeding to the next page"
            );
          } else {
            this.goNext();
          }
        }
        this.lastMove = "next";
      }
    },
    clickPrevious() {
      this.incorrectAnswerSelected = false;
      this.goPrevious();
      this.lastMove = "previous";
    },
    goNext() {
      this.closeFeedback();
      this.next();
    },
    goPrevious() {
      this.closeFeedback();
      this.previous();
    },
    setCheck(event) {
      this.check = event.target.checked;
    },
    setForCheck(questionId, for_check) {
      this.questions.map((q) => {
        if (q.type == "text") {
          q.for_check = false;
        } else {
          if (q.id == questionId) {
            q.for_check = for_check;
            if (!for_check) {
              q.answers = [];
            }
          }
        }
      });
    },
    validateNext() {
      // checks if all questions that have to be considered for
      // validation during page progression have atleast 1 answer
      let validate = false;
      this.questions.forEach((question) => {
        if (question.for_check && question.answers.length == 0) {
          validate = true;
        }
      });
      return validate;
    },
  },
};
</script>

<style scoped>
.question-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  min-width: 60%;
}

.mediaContainer {
  align-self: center;
  width: 50vw;
  border-radius: 5px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.55);
  margin-bottom: 20px;
}

.pageImg {
  border-radius: 5px;
  display: block;
  /* object-fit: contain; */
  height: 100%;
  width: 50vw;
  box-shadow: 0px 0px 12px -3.5px rgba(0, 0, 0, 0.55);
}

.pageVideo {
  border-radius: 5px;
  display: block;
  /* object-fit: contain; */
  height: 100%;
  width: 50vw;
  box-shadow: 0px 0px 12px -3.5px rgba(0, 0, 0, 0.55);
}

#incorrectAnswerMessage {
  font-size: 12px;
  color: red;
  text-align: center;
}

.btn-row {
  align-self: center;
  min-width: 30vw;
  max-width: 50vw;
  display: flex;
  justify-content: space-between;
}

.btn-row>button {
  transition: 0.2s ease;
  background-color: #789cff;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
  font-family: inherit;
  outline: none;
  border: none;
  font-size: 0.9rem;
}

.btn-row>button:hover {
  background-color: #608aff;
}

button:disabled {
  background-color: grey;
}

button:disabled:hover {
  background-color: grey;
}
</style>
