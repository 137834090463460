import BaseButton from "../components/BaseButton";
import BaseInput from "../components/BaseInput";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner.vue";
import DataTable from "../components/DataTable.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import Badge from "../components/Badge";
// import BaseAlert from "../components/BaseAlert";
// import BaseCheckbox from "../components/BaseCheckbox";
// import BaseDropdown from "../components/BaseDropdown";
// import BaseHeader from "../components/BaseHeader";
// import BaseNav from "../components/BaseNav";
// import BasePagination from "../components/BasePagination";
// import BaseProgress from "../components/BaseProgress";
// import BaseSwitch from "../components/BaseSwitch";
// import BaseRadio from "../components/BaseRadio";
// import BaseTable from "../components/BaseTable";
// import Card from "../components/Card";
// import StatsCard from "../components/StatsCard";
// import TabPane from "../components/Tabs/TabPane";
// import Tabs from "../components/Tabs/Tabs";

// import { ElTooltip, ElPopover } from "element-plus";

const GlobalComponents = {
  install(app) {
    app.component("base-button", BaseButton);
    app.component("base-input", BaseInput);
    app.component("modal", Modal);
    app.component("Spinner", Spinner);
    app.component("DataTable", DataTable);
    app.component("font-awesome-icon", FontAwesomeIcon);
    // app.component("badge", Badge);
    // app.component("base-alert", BaseAlert);
    // app.component("base-checkbox", BaseCheckbox);
    // app.component("base-dropdown", BaseDropdown);
    // app.component("base-header", BaseHeader);
    // app.component("base-nav", BaseNav);
    // app.component("base-pagination", BasePagination);
    // app.component("base-progress", BaseProgress);
    // app.component("base-switch", BaseSwitch);
    // app.component("base-radio", BaseRadio);
    // app.component("base-table", BaseTable);
    // app.component("card", Card);
    // app.component("stats-card", StatsCard);
    // app.component("tab-pane", TabPane);
    // app.component("tabs", Tabs);
    // app.use(ElTooltip);
    // app.use(ElPopover);
  },
};

export default GlobalComponents;
