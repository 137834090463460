export default {
  state: {
    visible: "",
    text: "",
  },
  mutations: {
    createErrModal(state, slide) {
      state.visible = slide.visible;
      state.text = slide.text;
    },
    destroyErrModal(state) {
      state.visible = false;
      state.text = "";
    },
  },
  actions: {
    create({ commit }, err) {
      commit("createErrModal", err);
    },
    exit({ commit }) {
      commit("destroyErrModal");
    },
  },
  namespaced: true,
};
