<template>
  <div class="card">
    <div class="card-body">
      <h2>Your Profile</h2>
      <div class="section-b">
        <div class="profile-picture">{{ initials }}</div>
        <div class="user-name-email">
          <div class="user-name">{{ name }}</div>
          <div class="user-email">{{ email }}</div>
        </div>
      </div>
      <div class="section" v-if="company">
        <div class="label">Company:</div>
        <div class="value">{{ company.name }}</div>
      </div>
      <div class="section" v-if="department">
        <div class="label">Department:</div>
        <div class="value">{{ department.name }}</div>
      </div>
      <div class="section">
        <div class="label">Role:</div>
        <div class="value">{{ role }}</div>
      </div>
      <div class="section">
        <div class="label">Date Created:</div>
        <div class="value">{{ created_at }}</div>
      </div>

      <div class="section" v-if="showEmailNotifications">
        <div class="label">Email Notification:</div>
        <label class="switch">
          <input type="checkbox" v-model="sendNotification" @change="onNotificationChange" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <LocationNote
      v-if="enableLocationNote"
      :show="showLocationNote"
      @close="showLocationNote = false"
    />
  </div>
</template>

<script>
import LocationNote from "../components/LocationNote";
import { roles } from "../helpers/constants";
import axios from "axios";

export default {
  components: {
    LocationNote,
  },
  props: [
    "name",
    "email",
    "company",
    "department",
    "created_at",
    "role",
    "email_notifications",
  ],
  data() {
    return {
      showLocationNote: false,
      enableLocationNote: false,
      sendNotification: false,
    };
  },
  computed: {
    initials() {
      if (!this.name) {
        return "";
      }

      let initials = this.name.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    },
    showEmailNotifications() {
      return this.role === roles.DEPARTMENT_ADMIN || this.role === roles.COMPANY_ADMIN || this.role === roles.THRIVE_ADMIN;
    },
  },
  mounted() {
    this.sendNotification = this.email_notifications;
  },
  methods: {
    onNotificationChange() {
      const params = {
        email_notifications: this.sendNotification,
      };
      axios({
        method: "get",
        url: "/api/email_notification_change",
        params: params,
      }).then((res) => {
        this.$store.commit("setUserNotifications", this.sendNotification);
        this.$swal.fire({
          position: "top-end",
          title: res.data.message,
          toast: true,
          icon: "success",
          showConfirmButton: false,
          timer: 5000,
        });
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  font-size: 1.5rem;
}
.setting-logo > i {
  font-size: 1.5rem;
  cursor: pointer;
}
.section-b {
  display: flex;
  width: fit-content;
  margin-bottom: 1rem;
}
.section-b > .profile-picture {
  margin-right: 1.5rem;
  border: 3px solid #789cff;
  border-radius: 5px;
  padding: 5px 10px;
  color: #789cff;
  font-weight: bold;
}
.section-b .user-name {
  color: #789cff;
  font-weight: 500;
  font-size: 1.25rem;
}
.section-b .user-email {
  color: #8d8d8d;
  font-size: 0.75rem;
}

.section {
  margin-bottom: 15px;
}
.section .label {
  color: black;
  font-weight: 400;
}
.section .value {
  color: #8d8d8d;
}

.popup-content {
  position: absolute;
  z-index: 1;
  bottom: 60%;
  left: 40%;
  z-index: 999;
}

/* Notification Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
