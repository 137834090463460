<template>
  <div>
    <div class="ui">
      <issue-severity-text v-if="severity" :severity="severity" />
      <p v-if="displayStatus" class="status" :class="currentStatus">
        Status: {{ displayStatus }}
      </p>

      <base-button type="secondary" @click="isOpen = true" v-if="hasIssue"
        >Change</base-button
      >
    </div>

    <teleport to="#modal-layer">
      <modal v-model:show="isOpen" bodyClasses="statusUpdateModal">
        <h3 class="text-center">Update the issue's status</h3>
        <p class="text-center status" :class="currentStatus">
          Current status: {{ displayStatus }}
        </p>
        <base-button type="warning" @click="assessAction" v-if="!isAssessed"
          >Mark Assessed</base-button
        >
        <base-button type="success" @click="resolveAction" v-if="!isResolved"
          >Mark Resolved</base-button
        >
        <base-button type="danger" @click="unresolveAction" v-if="!isUnresolved"
          >Mark Unresolved</base-button
        >
        <base-button
          type="secondary"
          class="cancelButton"
          @click="isOpen = false"
          >Cancel</base-button
        >
      </modal>
    </teleport>
  </div>
</template>

<script>
import axios from "axios";
import IssueSeverityText from "../components/IssueSeverityText.vue";
import { issueValues } from "../helpers/constants";

export default {
  props: ["severity", "status", "answerId"],
  data() {
    return {
      currentStatus: "",
      isOpen: false,
    };
  },
  components: {
    IssueSeverityText,
  },
  computed: {
    hasIssue() {
      return this.currentStatus !== issueValues.NOISSUE;
    },
    isResolved() {
      return this.currentStatus == issueValues.RESOLVED;
    },
    isAssessed() {
      return this.currentStatus == issueValues.ASSESSED;
    },
    isUnresolved() {
      return this.currentStatus == issueValues.UNRESOLVED;
    },
    displayStatus() {
      return this.hasIssue ? this.currentStatus : "no issue";
    },
  },
  created() {
    this.currentStatus = this.status;
  },
  methods: {
    assessAction() {
      this.currentStatus = issueValues.ASSESSED;
      this.isOpen = false;
      this.callUpdateApi(this.currentStatus);
    },
    resolveAction() {
      this.currentStatus = issueValues.RESOLVED;
      this.isOpen = false;
      this.callUpdateApi(this.currentStatus);
    },
    unresolveAction() {
      this.currentStatus = issueValues.UNRESOLVED;
      this.isOpen = false;
      this.callUpdateApi(this.currentStatus);
    },
    callUpdateApi(newStatus) {
      if (!this.answerId) {
        throw "Insufficient params for API calls";
      }
      axios.put("/api/userassessment/status", {
        user_assessment_answer_id: this.answerId,
        status: newStatus,
      });
    },
  },
};
</script>
    
<style>
.statusUpdateModal {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.statusUpdateModal .cancelButton {
  margin-top: 2rem;
}
</style>
<style scoped>
.ui {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  row-gap: 2rem;
  column-gap: 2rem;
  align-items: center;
}
.ui > * {
  margin: 0;
}

.status {
  text-transform: capitalize;
  font-weight: bold;
  border: 1px solid red;
  padding: 0.5rem;
}

.status.unresolved {
  border-color: #ff0000;
  color: #ff0000;
}

.status.assessed {
  border-color: #ffb116;
  color: #ffb116;
}

.status.resolved {
  border-color: green;
  color: green;
}

.status.noissue {
  border-color: #3273f6;
  color: #3273f6;
}
</style>