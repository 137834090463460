<template>
  <div class="questions-wrapper">
    <div class="question-progress mb-4">
      <Slider :modelValue="currentPage + 1" :min="0" :max="maxPages" :disabled="true" :tooltips="false" />
      {{ currentPage + 1 }} / {{ maxPages }}
    </div>
    <QuestionPage ref="page" :page="this.pageData.pages[currentPage]" :setAnswer="setAnswer"
      :removeAnswer="removeAnswer" :currentPage="currentPage" :maxPages="maxPages" :next="next" :previous="previous" />
  </div>
</template>
<script>
import axios from "axios";
import QuestionPage from "./QuestionPage";
import Slider from "@vueform/slider";
import debounce from "debounce";
export default {
  props: ["data", "initialPage"],
  data() {
    return {
      currentPage: this.initialPage,
      pageData: this.data,
      maxPages: this.data.pages.length,
    };
  },
  components: {
    QuestionPage,
    Slider,
  },
  created() {
    if (this.currentPage >= this.maxPages) {
      throw "Max page number exceeded";
    }
  },
  computed: {
    userAssessmentId() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    getOptions(answers) {
      return answers.map((x) => x.value);
    },
    next() {
      if (this.currentPage == this.maxPages - 1) {
        this.showResults();
        return;
      }
      this.currentPage++;
    },
    previous() {
      if (this.currentPage != 0) {
        this.currentPage--;
      }
    },
    setAnswer: debounce(function (data) {
      axios
        .post(`/api/userassessment/answer`, {
          ...data,
          userassessment_id: this.userAssessmentId,
        })
    }, 200),
    removeAnswer: debounce(function (data) {
      axios
        .delete(`/api/userassessment/answer`, {
          data: {
            ...data,
            userassessment_id: this.userAssessmentId,
          }
        })
    }, 200),
    async showResults() {
      this.$swal.fire({
        position: "top",
        toast: true,
        title:
          "That's it - you've completed the assessment.\nHere are the results.",
        icon: "success",
        showConfirmButton: true,
        timer: 7500,
      });

      await axios
        .post(`/api/userassessment/trigger-complete`, {
          userassessment_id: this.userAssessmentId,
        });

      this.$router.push(`/ua/${this.userAssessmentId}/results`);
    },
  },
};
</script>

<style scoped>
.question-progress {
  text-align: center;
  font-size: 0.9rem;
}
</style>
