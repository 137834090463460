<template>
  <div>
    <h3>
      Results: {{ $route.meta.description }}
      <Spinner v-if="isLoading" />
    </h3>

    <DataTable
      v-if="!isLoading && data.length"
      :data="data"
      :columns="tableColumns"
      :order="[0, 'asc']"
      :paginate="true"
      :pageLength="50"
    />
    <p v-if="!isLoading && data.length === 0">No entries available for this report.</p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    companyId: {
      type: Number,
      default: -1,
    },
    departmentId: {
      type: Number,
      default: -1,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    apiUrl: {
      type: String,
      default: "",
    },
    needsDepartment: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.update();
  },
  watch: {
    companyId() {
      this.update();
    },
    departmentId() {
      if (!this.needsDepartment) {
        return;
      }
      this.update();
    },
    apiUrl() {
      this.update();
    },
    columns() {
      this.update();
    },
    needsDepartment() {
      this.update();
    },
  },
  data() {
    return {
      data: [],
      isLoading: false, // is ajax data loading?
      isAwaitingUpdate: false, // prop update debounce
    };
  },
  computed: {
    hasCompany() {
      return this.companyId > 0;
    },
    hasDepartment() {
      return this.departmentId > 0;
    },
    tableColumns() {
      return this.columns.map((el) => ({ title: el.data, ...el }));
    },
  },
  methods: {
    update() {
      // queue update calls into a single 'loadData' call in the next tick
      if (this.isAwaitingUpdate) {
        return;
      }

      this.isAwaitingUpdate = true;
      this.$nextTick(() => this.loadData());
    },
    async loadData() {
      // DON'T CALL THIS DIRECTLY - CALL update() INSTEAD
      this.isAwaitingUpdate = false;

      // clear the existing table
      this.data = [];

      if (!this.hasCompany) {
        return;
      }
      if (this.needsDepartment && !this.hasDepartment) {
        return;
      }
      if (!this.apiUrl) {
        throw "apiURL prop required";
      }
      if (!this.columns || this.columns.length === 0) {
        throw "columns prop required";
      }

      let url = `${this.apiUrl}/${this.companyId}`;
      if (this.needsDepartment) {
        url += `/${this.departmentId}`;
      }

      this.$emit("loadStart");
      this.isLoading = true;

      const res = await axios.get(url);

      this.$emit("loadEnd");
      this.isLoading = false;
      this.data = res.data;
    },
  },
};
</script>