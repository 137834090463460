import { createRouter, createWebHistory } from "vue-router";
import { store } from "../store/index";
import { roles } from "../helpers/constants";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import InitView from "../views/InitView.vue";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Assessments from "../views/Assessments.vue";
import Users from "../views/Users.vue";
import Results from "../views/Results.vue";
import AssessmentQuiz from "@/views/AssessmentQuiz.vue";
import CompanyDepartments from "../views/CompanyDepartments.vue";
import ReminderEmails from "../views/ReminderEmails.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import LicenceManagement from "../views/LicenceManagement.vue";
import Reports from "../views/Reports.vue";
import ReportWrapper from "../views/reports/ReportWrapper.vue";
import ReportTextWrapper from "../views/reports/ReportTextWrapper.vue";
import StripeResult from "../views/StripeResult.vue";

/**
 *  Route Meta
 *  pageTitle - (optional) title to display on page
 *  isPublic - (optional) can be accessed by a public user - if not specified, the user must be logged in
 *  auth - (optional) [role1, role2] - restrict the page to users with a specific role. if not provided any logged in user can access
 *
 *  By default all routes require a logged in user. Use isPublic meta for public routes. Use auth[] meta to restrict the page to certain user roles only.
 *  The Thrive Admin user role has access to all pages and doesn't need to be included in the auth[] array.
 *  If you include isPublic and auth, the route will no longer be public
 */
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/init",
        component: InitView,
        meta: { isPublic: true, pageTitle: "" },
      },
      {
        path: "/dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/assessments",
        components: { default: Assessments },
        meta: {
          auth: [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN],
        },
      },
      {
        path: "/ua/:id", // ua = user assessment
        component: AssessmentQuiz,
        meta: { pageTitle: "Your Assessment" },
      },
      {
        path: "/ua/:id/review",
        component: Results,
        meta: {
          pageTitle: "Assessment Review",
          auth: [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN],
        },
        props: {
          allowAddNotes: true,
          allowStatusChange: true,
          showSolutions: true,
          showSummary: false,
        },
      },
      {
        path: "/ua/:id/results",
        component: Results,
        meta: {
          pageTitle: "Assessment Results",
        },
        props: {
          allowAddNotes: false,
          allowStatusChange: false,
          showSolutions: false,
          showSummary: true,
        },
      },
      {
        path: "/users",
        components: { default: Users },
        meta: { auth: [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN] },
      },
      {
        path: "/companydepartments",
        components: { default: CompanyDepartments },
        meta: {
          pageTitle: "Company Departments",
          auth: [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN],
        },
      },
      {
        path: "/licence-management",
        name: "licenses",
        components: { default: LicenceManagement },
        meta: {
          pageTitle: "Licence Management",
          auth: [roles.COMPANY_ADMIN],
        },
      },
      {
        path: "/reminder-emails",
        components: { default: ReminderEmails },
        meta: {
          pageTitle: "Reminder Emails (super admin)",
          auth: [roles.THRIVE_ADMIN],
        },
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
        meta: { auth: [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN] },
        children: [
          {
            path: "not-completed",
            name: "reports-not-completed",
            component: ReportWrapper,
            meta: {
              pageTitle: "Overdue Assessments",
              description: "Overdue Assessments",
            },
            props: {
              apiUrl: "/api/reports/not-completed",
              columns: [
                { data: "name" },
                { data: "email" },
                { data: "department" },
                {
                  title: "due",
                  data: ({ due, due_machine }, purpose) =>
                    purpose === "sort" ? due_machine : due,
                },
                { data: "assessment" },
              ],
            },
          },
          {
            path: "not-allocated",
            name: "reports-not-allocated",
            component: ReportWrapper,
            meta: {
              pageTitle: "No Assessment Allocated",
              description: "Users not yet allocated an Assessment",
            },
            props: {
              apiUrl: "/api/reports/not-allocated",
              columns: [
                { data: "name" },
                { data: "email" },
                { data: "department" },
              ],
            },
          },
          {
            path: "one-year-ago",
            name: "reports-one-year-ago",
            component: ReportWrapper,
            meta: {
              pageTitle: "More Than 1 Year Ago",
              description:
                "Users that last completed an assessment more than 1 year ago",
            },
            props: {
              apiUrl: "/api/reports/more-than-year",
              columns: [
                { data: "name" },
                { data: "email" },
                { data: "department" },
                {
                  title: "last",
                  data: ({ last, last_machine }, purpose) =>
                    purpose === "sort" ? last_machine : last,
                },
              ],
            },
          },
          {
            path: "identified-issues-incidence",
            name: "reports-identified-issues-incidence",
            component: ReportTextWrapper,
            meta: {
              pageTitle: "Top 10 Identified Issues",
              description: "Top 10 identified issues per department",
            },
            props: {
              apiUrl: "/api/reports/issues-incidence",
              columns: [{ data: "occurrences" }, { data: "issue" }],
              needsDepartment: true,
            },
          },
          {
            path: "identified-issues-department",
            name: "reports-identified-issues-department",
            component: ReportWrapper,
            meta: {
              pageTitle: "Number of Issues Per Assessment Type",
              description: "Number of issues identified per type of assessment",
            },
            props: {
              apiUrl: "/api/reports/issues-per-type",
              columns: [{ data: "count" }, { data: "name" }],
              needsDepartment: true,
            },
          },
          {
            path: "assessments-outstanding",
            name: "reports-assessments-outstanding",
            component: ReportWrapper,
            meta: {
              pageTitle: "Assessments Outstanding",
              description:
                "Assessments outstanding, per Dept, per assessment type",
            },
            props: {
              apiUrl: "/api/reports/assessments-outstanding",
              columns: [
                { data: "department" },
                { data: "assessment" },
                { data: "count" },
              ],
            },
          },
        ],
      },
      {
        path: "/payment-result/:sessionId",
        component: StripeResult,
        meta: {
          pageTitle: "Payment Complete",
          auth: [roles.COMPANY_ADMIN, roles.DEPARTMENT_ADMIN],
        },
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    meta: { isPublic: true },
    children: [
      {
        path: "/login",
        components: { default: Login },
      },
      {
        path: "/logout",
        components: { default: Logout },
      },
      {
        path: "/forgot-password",
        components: { default: ForgotPassword },
      },
      {
        path: "/reset",
        components: { default: ResetPassword },
      },
    ],
  },
];

const base = process.env.BASE_URL;

const router = createRouter({
  history: createWebHistory(base),
  linkActiveClass: "active",
  routes,
});

router.beforeEach(async (to) => {
  if (to.meta.isPublic && !to.meta.auth) {
    return true;
  }

  if (!store.state.isInitialised) {
    return { path: "/init", query: { returnUrl: to.path } };
  }

  // store is initialised, check auth requirements
  const loginRoute = { path: "/login", query: { returnUrl: to.path } };

  const user = store.state.user;

  if (!user) {
    return loginRoute;
  }

  const role = user.role;
  const auth = to.meta.auth;

  if (user.isThriveAdmin || !Array.isArray(auth) || auth.includes(role)) {
    return true;
  }

  // user doesn't have the correct permissions
  return loginRoute;
});

export default router;
