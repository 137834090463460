<template>
  <p class="severity" :class="`level-${severity}`">
    {{ severityText }}
  </p>
</template>
<script>
export default {
  props: ["severity"],
  computed: {
    severityText() {
      let t;
      switch (parseInt(this.severity)) {
        case 1:
          t = "Low";
          break;
        case 2:
          t = "Medium";
          break;
        case 3:
          t = "High";
          break;
        default:
          return "";
      }
      return `Issue Severity: ${t}`;
    },
  },
};
</script>
<style scoped>
.severity {
  font-weight: bold;
}
.severity.level-1 {
  color: #008000;
}
.severity.level-2 {
  color: #ffb116;
}
.severity.level-3 {
  color: #ff0000;
}
</style>