<template>
  <div class="container-fluid pt-5 pt-md-8">
    <div class="row">
      <div class="col-sm">
        <div class="card">
          <div class="card-body">
            <company-credit-display
              :companyId="$store.state.user.company.id"
            ></company-credit-display>
          </div>
        </div>
      </div>
      <div class="col-sm mt-sm-0 mt-4">
        <div class="card">
          <div class="card-body">
            <PurchaseWidget />
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-body">
        <h3>Purchase History</h3>
        <LicenceHistory />
      </div>
    </div>
  </div>
</template>
<script>
import LicenceHistory from "../components/SortableTables/LicenceHistory";
import CompanyCreditDisplay from "../components/CompanyCreditDisplay.vue";
import PurchaseWidget from "../components/PurchaseWidget.vue";
export default {
  components: {
    CompanyCreditDisplay,
    PurchaseWidget,
    LicenceHistory,
  },
  created() {
    const user = this.$store.state.user;
    if (!(user.isThriveAdmin || user.isCompanyAdmin)) {
      this.$swal.fire({
        position: "top",
        title: "This page is only available for higher-level admin users.",
        toast: true,
        icon: "error",
        showConfirmButton: true,
        timer: 5000,
      });
      this.$router.replace("/dashboard");
      return;
    }
  },
};
</script>