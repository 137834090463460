<template>
  <div class="container-fluid pt-5 pt-md-8">
    <AssessmentsTable></AssessmentsTable>
  </div>
</template>

<script>
import AssessmentsTable from "../components/SortableTables/AssessmentsTable.vue";
export default {
  components: {
    AssessmentsTable,
  },
};
</script>

<style></style>
