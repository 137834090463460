<template>
  <transition name="sweep">
    <div class="main-wrapper" v-if="visible">
      <div class="error-message"><strong>Error:</strong> {{ text }}</div>
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    ...mapState("errors", ["visible", "text"]),
  },
  watch: {
    visible() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      if (this.visible) {
        this.timer = setTimeout(() => {
          this.timer = null;
          this.$store.dispatch("errors/exit");
        }, 5000);
      }
    },
  },
};
</script>
<style scoped>
.main-wrapper {
  width: 100%;
  background-color: rgb(249, 117, 117);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
}
.error-message {
  width: 60%;
  margin: 0 auto;
  text-align: left;
}
.close-button {
  margin-left: 20px;
  display: inline-block;
}

.sweep-enter-active,
.sweep-leave-active {
  transition: 1s;
}

.sweep-enter,
.sweep-leave-to {
  transform: translateY(-100%);
}
</style>
