<template>
  <div class="wrapper" :style="{ width: size, height: size }" v-if="immediate || showMe">
    <font-awesome-icon :icon="icon" spin class="anim" />
  </div>
</template>
<script>
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
export default {
  props: {
    size: {
      type: String,
      default: "1rem",
    },
    immediate: {
      type: Boolean,
      default: false,
      description: "Show spinner immediately and not after a short timeout",
    },
  },
  data() {
    return {
      icon: faSpinner,
      showMe: false,
      timer: null,
    };
  },
  created() {
    if (!this.immediate) {
      this.timer = setTimeout(() => (this.showMe = true), 200);
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: inline-block;
  padding-top: 3px;
}
.anim {
  height: 100%;
  position: absolute;
  animation-duration: 1s;
}
</style>