<template>
    <div>
        <p v-if="isLoading">
            <Spinner></Spinner>
        </p>
        <div class="display-option-wrapper mt-4" v-if="hasAssessment">
            <p>Display Options:</p>
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="selectMode"
                    value="active"
                    id="radio_active"
                />
                <label class="form-check-label" for="radio_active">Active Assessments Only</label>
            </div>
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="selectMode"
                    value="archived"
                    id="radio_false"
                />
                <label class="form-check-label" for="radio_false">Archived Assessments Only</label>
            </div>
        </div>
        <DataTable
            class="mt-4"
            v-if="!isLoading && data.length > 0"
            ref="dataTable"
            :data="data"
            :columns="columns"
            :order="tableOrder"
            :filters="filters"
            :selectable="true"
            :searchable="true"
            :paginate="true"
            :pagelengthable="true"
            :pageLength="pageLength"
            :exportable="true"
            exportFilename="user-download.csv"
            noDataText="No users match your selection."
            @selectionChanged="onSelectionChanged"
            @pageLengthChanged="onPageLengthChanged"
            @orderChanged="onTableOrderChanged"
        ></DataTable>
        <p class="mt-4" v-if="!isLoading && data.length === 0">No users match your selection.</p>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
export default {
    props: {
        assessmentId: {
            type: Number,
        }
    },
    data() {
        return {
            isThriveAdmin: this.$store.state.user.isThriveAdmin,
            isDepartmentAdmin: this.$store.state.user.isDepartmentAdmin,
            isLoading: true,
            data: [],
            pageLength: 20,
            tableOrder: [1, "asc"],
            selectMode: "active", // active, archived
        };
    },
    computed: {
        hasAssessment() {
            return this.assessmentId > 0;
        },
        filters() {
            if (!this.hasAssessment) {
                return [];
            }
            const value = this.selectMode === "archived" ? 0 : 1;
            return [[8, value]]
        },
        columns() {
            return [
                { data: "id", title: "id", visible: this.isThriveAdmin, className: "text-center" },
                { data: "name", title: "name" },
                { data: "email", title: "email" },
                { data: "company.name", title: "company", visible: this.isThriveAdmin },
                { data: "department.name", title: "department", visible: !this.isDepartmentAdmin },
                { data: "role", title: "role" },
                {
                    title: "Assessment State", data: (row, purpose) => {
                        if (purpose === "sort") {
                            if (row.complete_date) {
                                return `C ${row.complete_date_machine}`;
                            }
                            if (row.reminder_sent) {
                                return `N ${row.created_at_machine}`
                            }
                            return `P ${row.created_at_machine}`
                        } else {
                            if (row.complete_date) {
                                return `Complete (${row.complete_date})`
                            }
                            if (row.reminder_sent) {
                                return `Not complete (${row.created_at})`
                            }
                            return `Pending (${row.created_at})`
                        }
                    }, visible: this.hasAssessment
                },
                {
                    data: (row) => row.licence_id || row.purchase_id || "",
                    title: "Licence Id",
                    className: "text-center",
                    visible: this.hasAssessment && this.isThriveAdmin,
                },
                {
                    title: "Active", data: "is_active", visible: false,
                },
                {
                    title: "Assessment Id", data: "assessment_id", className: "text-center", visible: this.hasAssessment && this.isThriveAdmin
                },
            ].filter(el => el);
        }
    },
    created() {
        this.isLoading = true;
        this.getUsers();
    },
    watch: {
        assessmentId() {
            this.getUsers();
        }
    },
    methods: {
        getUsers() {
            const params = {
                assessment_id: this.assessmentId,
            };

            this.isLoading = true;
            this.data = [];
            this.selectMode = "active";
            this.emitSelectionChanged([]); // clear selected users

            axios({
                method: "get",
                url: "/api/users",
                params: params,
            }).then((res) => {
                this.isLoading = false;
                this.data = res.data;
            });
        },
        onSelectionChanged(rowData) {
            this.emitSelectionChanged(rowData);
        },
        onPageLengthChanged(len) {
            this.pageLength = len;
        },
        onTableOrderChanged(order) {
            this.tableOrder = order;
        },
        emitSelectionChanged(rowData) {
            this.$emit("selectionChanged", rowData);
        },
    },
};
</script>
<style scoped>
.display-option-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.7rem;
    gap: 1.5rem;
}
.display-option-wrapper p {
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.form-check {
    margin-top: 5px;
}
.display-option-wrapper .form-check-input {
    margin-top: 0;
}
</style>