<template>
  <div class="container-fluid pt-5 pt-md-8" v-if="user">
    <base-button
      class="primary mb-5"
      @click="doCreateCompany = true"
      v-if="user.isThriveAdmin"
      :icon="iconCreateCompany"
      >Create Company</base-button
    >
    <div class="company-department-wrapper" v-if="!isLoadingData">
      <div
        class="company-department-tile"
        v-for="item in orderedCompanyList"
        :key="item.company.name"
      >
        <div class="title-header">
          {{ item.company.name }}<br />
          <base-button
            class="btn-sm"
            v-if="user && (user.isThriveAdmin || user.isCompanyAdmin)"
            @click="createDepartment(item)"
            :icon="iconCreateCompany"
            >Create Department</base-button
          >
        </div>
        <div
          class="department-wrapper"
          v-for="department in item.departments"
          :key="item.company.name + '-' + department.name"
        >
          <div class="department-name">
            {{ department.name }}
          </div>
        </div>
      </div>
    </div>
    <CreateCompany
      :show="doCreateCompany"
      @close="doCreateCompany = false"
    ></CreateCompany>
    <CreateDepartment
      :item="createDepartmentData"
      @close="createDepartmentClosed"
    ></CreateDepartment>
  </div>
</template>

<script>
import axios from "axios";
import CreateDepartment from "@/components/PopUps/CreateDepartment.vue";
import CreateCompany from "@/components/PopUps/CreateCompany.vue";
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
export default {
  data() {
    return {
      isLoadingData: true,
      companyArray: [],
      user: null,
      doCreateCompany: false,
      createDepartmentData: null,
      iconCreateCompany: faCirclePlus,
    };
  },
  components: {
    CreateDepartment,
    CreateCompany,
  },
  computed: {
    userCompanyName() {
      return this.user ? this.user.company.name : "";
    },
    orderedCompanyList() {
      if (!this.companyArray) {
        return [];
      }
      const sorted = [...this.companyArray];
      return sorted.sort(function (a, b) {
        var textA = a.company.name.toUpperCase();
        var textB = b.company.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
  },
  methods: {
    createDepartment(dataItem) {
      this.createDepartmentData = dataItem;
    },
    createDepartmentClosed() {
      this.createDepartmentData = null;
    },
  },
  created() {
    this.user = this.$store.state.user;
    this.role = this.user.role;
    this.companyArray = [];
    this.isLoadingData = true;

    const self = this;

    if (this.user.isCompanyAdmin) {
      axios.get("/api/companies/" + this.user.company.id).then((res) => {
        self.companyArray = [
          {
            company: self.user.company,
            departments: res.data.departments,
          },
        ];
        self.isLoadingData = false;
      });
    } else if (this.user.isThriveAdmin) {
      axios.get("/api/companies").then((res) => {
        const total = res.data.companies.length;
        let remaining = total;

        for (var i = 0; i < res.data.companies.length; i++) {
          let company = res.data.companies[i];
          axios.get("/api/companies/" + company.id).then((val) => {
            self.companyArray.push({
              company: val.data.company,
              departments: val.data.departments,
            });
            if (--remaining == 0) {
              self.isLoadingData = false;
            }
          });
        }
      });
    }
  },
};
</script>

<style scoped>
.company-department-wrapper {
  display: flex;
  flex-direction: row;
  /* flex: 0 0 33.333333%; */
  flex-wrap: wrap;
  gap: 1.5rem;
}

.company-department-tile {
  border: 1px solid black;
  border-radius: 0.75rem;
  display: flex;
  box-shadow: 10px 10px 4px rgba(77, 77, 77, 0.15);
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 400px;
  max-width: 400px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  background-color: #fff;
}

.title-header {
  padding-bottom: 1.5rem;
  text-align: center;
  background-color: #fff;
  font-size: 1.5rem;
  position: sticky;
  top: 0.5rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid #9f9f9f;
}

.department-wrapper {
  display: flex;
  border-bottom: 1px solid #9f9f9f;
  padding: 0.5rem 2rem;
}

.department-wrapper:nth-child(even) {
  background-color: #ebebeb;
}

.department-name {
  align-self: center;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 1rem;
}
</style>
