<template>
  <div class="row justify-content-center formContainer">
    <div class="col-sm-6 form">
      <img src="../assets/thrive4life-logo.png" class="logoImage" />
      <img src="../assets/loginHeader.svg" class="headerImage" />
      <form @submit.prevent="submit">
        <label>Email:</label>
        <base-input
          placeholder="Email"
          v-model="email"
          required
          autofocus
          @input="v$.email.$touch()"
          @blur="v$.email.$touch()"
        >
        </base-input>
        <p class="text-danger" v-if="v$.email.$error">
          Please enter a valid email address.
        </p>
        <base-input type="submit" class="btn btn-primary" value="Continue" />
      </form>
      <p class="text-center">
        <router-link class="text-primary text-small" to="/login"
          >Return to login</router-link
        >
      </p>
    </div>
    <div class="col-sm-6 info">
      <h2 class="card-title">Thrive4Life Assessment Platform</h2>
      <p class="text-center">
        Your employer has requested you to use this training and assessment
        tool, so that they can ensure that you are able to work safely and
        comfortably.
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "forgot-password",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  components: {
  },
  methods: {
    submit: function () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      axios
      .post("/api/password_reset", {
        email: this.email,
      })
      .then((res) => {
        if (res.status == 200) {
          this.$swal.fire({
            position: "top-end",
            title: res.data.message,
            toast: true,
            icon: "success",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    },
  },
};
</script>
<style>
.formContainer {
  background: white;
  position: relative;
  padding: 10px;
}

.form {
  width: 60%;
  border-right: 1px solid #7889a5;
}

.logoImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
}

.headerImage {
  width: 50%;
  display: block;
  margin: 0 auto 50px auto;
}

.info {
  padding: 100px 0;
  text-align: center;
}
</style>
