<template>
  <button class="mb-4 mb-lg-0 grid-title-dropdown p-0">
    <slot></slot><i class="arrowDown"></i>
  </button>
</template>
<style scoped>
.arrowDown {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  left: 5px;
  top: -3px;
}
.grid-title-dropdown {
  color: black;
  font-size: 18px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: transparent;
}
.grid-title-dropdown:hover {
  cursor: pointer;
}
.grid-title-dropdown:focus {
  border: none;
  outline: none;
}
</style>