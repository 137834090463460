<template>
  <div class="container-fluid pt-5 pt-md-8">
    <div class="card">
      <div class="card-body">
        <div>
          <strong>Report:</strong>
          <select
            v-model="selectedReportId"
            @change="onReportSelect"
            class="form-control"
            :disabled="isReportLoading"
          >
            <option value="-1" v-if="!isSubPage">Select a Report...</option>
            <option
              v-for="(item, index) in reports"
              v-bind:key="index"
              v-bind:value="index"
            >{{ item.name }}</option>
          </select>
        </div>
        <div class="mt-4" v-if="isSubPage && !forcedCompany">
          <strong>Company:</strong>
          <company-selector
            v-model="selectedCompanyId"
            :forceValue="forcedCompany"
            :disabled="isReportLoading"
          />
        </div>
        <div class="mt-4" v-if="isSubPage && needsDepartment">
          <strong>Department:</strong>
          <department-selector
            v-model="selectedDepartmentId"
            :companyId="selectedCompanyId"
            :forceValue="forcedDepartment"
            :disabled="isReportLoading"
          />
        </div>
      </div>
    </div>
    <div class="card mt-4" v-if="canShowReport">
      <div class="card-body">
        <router-view
          :companyId="selectedCompanyId"
          :departmentId="selectedDepartmentId"
          @loadStart="onReportLoadStart"
          @loadEnd="onReportLoadEnd"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import CompanySelector from "../components/CompanySelector.vue";
import DepartmentSelector from "../components/DepartmentSelector.vue";
export default {
  name: "Reports",
  components: {
    CompanySelector,
    DepartmentSelector,
  },
  data() {
    return {
      showMenu: true,
      isSubPage: false,
      reports: [],
      selectedReportId: -1,
      selectedCompanyId: -1,
      selectedDepartmentId: -1,
      forcedCompany: "",
      forcedDepartment: "",
      needsDepartment: false,
      isReportLoading: false,
    };
  },
  created() {
    // pre-configure company and department info
    const user = this.$store.state.user;
    if (!user || user.isEndUser) {
      throw "Permission denied - must be admin user";
    }

    if (!user.isThriveAdmin) {
      this.selectedCompanyId = user.company.id;
      this.forcedCompany = user.company.name;

      if (user.isDepartmentAdmin) {
        this.selectedDepartmentId = user.department.id;
        this.forcedDepartment = user.department.name;
      }
    }

    // process route information
    this.initFromRoute();
  },
  watch: {
    $route() {
      this.initFromRoute();
    },
    selectedCompanyId() {
      this.selectedDepartmentId = -1;
    },
  },
  computed: {
    canShowReport() {
      return (
        this.isSubPage && // report page selected
        this.selectedCompanyId > 0 && // company selected
        (!this.needsDepartment || this.selectedDepartmentId > 0) // doesn't need department OR department selected
      );
    },
  },
  methods: {
    initFromRoute() {
      // create menu items from route info
      // a bit of a hack - walk up the route matches to find the first route with children
      // we take this to be the route for the reports overview page
      const maxIndex = this.$route.matched.length - 1;
      let index = maxIndex;
      while (index > 0 && this.$route.matched[index].children.length === 0) {
        index--;
      }

      if (index < 0) {
        console.error("No reports overview route found");
      }

      this.isSubPage = index < maxIndex; // current page is a nested view
      this.showMenu = !this.isSubPage;

      if (!this.reports.length) {
        const menuChildren = this.$route.matched[index].children;
        this.reports = menuChildren.map((item) => ({
          name: item.meta.description,
          routeName: item.name,
        }));
      }

      if (this.isSubPage) {
        // select correct report
        const routeName = this.$route.name;
        for (let i = 0; i < this.reports.length; i++) {
          if (this.reports[i].routeName === routeName) {
            this.selectedReportId = i;
            break;
          }
        }

        const matchedRoute = this.$route.matched[maxIndex];
        this.needsDepartment = !!matchedRoute?.props?.default?.needsDepartment;
      } else {
        // no report selected
        this.selectedReportId = -1;
        this.needsDepartment = false;
      }
    },
    onReportSelect() {
      this.showMenu = false;
      const report = this.reports[this.selectedReportId];
      this.$router.push({ name: report.routeName });
    },
    onReportLoadStart() {
      this.isReportLoading = true;
    },
    onReportLoadEnd() {
      this.isReportLoading = false;
    },
  },
};
</script>
