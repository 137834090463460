<template>
  <modal v-model:show="isVisible" @close="close">
    <h3>Create User</h3>
    <form @submit.prevent="createUser">
      <base-input type="text" v-model="name" placeholder="Name..." />
      <base-input type="text" v-model="email" placeholder="Email..." />

      <div class="form-group" v-if="$store.state.user && $store.state.user.isThriveAdmin && !forcedCompany">
        <company-selector
          v-model="selectedCompany"
          :forceValue="forcedCompany"
        />
      </div>
      <div class="form-group">
        <department-selector
          v-model="selectedDepartment"
          :companyId="selectedCompany"
          :forceValue="forcedDepartment"
        />
      </div>

      <base-input
        v-if="roles.length == 1"
        type="text"
        :value="'Role: ' + selectedRole"
        disabled="true"
        readonly="true"
      />

      <div class="form-group" v-if="roles.length > 1">
        <select v-model="selectedRole" class="form-control">
          <option value="-1" selected>Select Role...</option>
          <option v-for="role in roles" :key="role" :value="role">
            {{ role }}
          </option>
        </select>
      </div>
      <p v-if="error" class="text-danger">{{ error }}</p>
      <base-input type="submit" class="btn-primary" />
    </form>
    <div class="text-center">
      <base-button type="secondary" @click="close">Cancel</base-button>
    </div>
  </modal>
</template>

<script>
import { roles } from "../../helpers/constants";
import { stringValue } from "../../helpers/validators";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, email, minValue } from "@vuelidate/validators";
import CompanySelector from "../CompanySelector.vue";
import DepartmentSelector from "../DepartmentSelector.vue";

export default {
  components: {
    CompanySelector,
    DepartmentSelector,
  },
  props: ["show", "user"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isVisible: false,
      roles: [],
      companies: [],
      name: "",
      email: "",
      selectedCompany: -1,
      selectedDepartment: -1,
      selectedRole: -1,
      forcedCompany: "",
      forcedDepartment: "",
      error: "",
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    selectedCompany: { required, min: minValue(1) },
    selectedDepartment: { required, min: minValue(1) },
    selectedRole: { required, stringValue },
  },
  watch: {
    show: function (newVal) {
      this.isVisible = newVal;
      if (this.isVisible) {
        this.initForm();
      }
    },
  },
  methods: {
    initForm() {
      if (!this.user) {
        console.error("no user defined");
        return;
      }

      // available roles
      if (this.user.isThriveAdmin) {
        this.roles.push(roles.THRIVE_ADMIN);
        this.roles.push(roles.COMPANY_ADMIN);
        this.roles.push(roles.DEPARTMENT_ADMIN);
        this.roles.push(roles.END_USER);

        this.roles = [
          roles.THRIVE_ADMIN,
          roles.COMPANY_ADMIN,
          roles.DEPARTMENT_ADMIN,
          roles.END_USER,
        ];
      } else if (this.user.isCompanyAdmin) {
        this.roles = [
          roles.COMPANY_ADMIN,
          roles.DEPARTMENT_ADMIN,
          roles.END_USER,
        ];
        this.forcedCompany = this.user.company.name;
        this.selectedCompany = this.user.company.id;
      } else if (this.user.isDepartmentAdmin) {
        this.roles = [roles.END_USER];
        this.forcedCompany = this.user.company.name;
        this.selectedCompany = this.user.company.id;
        this.forcedDepartment = this.user.department.name;
        this.selectedDepartment = this.user.department.id;
      }

      if (this.roles.length === 1) {
        this.selectedRole = this.roles[0];
      }
    },
    close() {
      this.isVisible = false;
      this.error = "";
      this.name = "";
      this.email = "";
      this.selectedRole = -1;
      this.selectedCompany = -1;
      this.selectedDepartment = -1;
      this.forcedCompany = "";
      this.forcedDepartment = "";
      this.$emit("close");
    },
    createUser() {
      this.error = "";
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error =
          "Please complete all the fields and check the email address.";
        return;
      }

      // we handle our own errors
      axios
        .create()
        .post("/api/users", {
          users: [
            {
              name: this.name,
              email: this.email,
              role: this.selectedRole,
              company: "" + this.selectedCompany, // send as string
              department: "" + this.selectedDepartment, // send as string
            },
          ],
        })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          this.error = err.response.data;
          console.error(err, err.response);
        });
    },
  },
};
</script>

<style scoped>
</style>
