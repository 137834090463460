<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h3>Results summary</h3>
        </div>
        <div class="col text-right" :class="'type-' + severity">
          Severity: {{ severity }}
        </div>
      </div>
      <div class="row">
        <div class="col results-summary-container">
          <div class="results-title">RESOLVED</div>
          <div class="results-outcome">
            {{ risks }}
          </div>
        </div>
        <div class="col results-summary-container">
          <div class="results-title">FOLLOWUP</div>
          <div class="results-outcome">
            {{ followup }}
          </div>
        </div>
        <div class="col results-summary-container">
          <div class="results-title">UNRESOLVED</div>
          <div class="results-outcome">
            {{ unresolved }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col results-summary-container">
          <div class="results-title">LOW RISK</div>
          <div class="results-outcome">
            {{ low }}
          </div>
        </div>
        <div class="col results-summary-container">
          <div class="results-title">MEDIUM RISK</div>
          <div class="results-outcome">
            {{ medium }}
          </div>
        </div>
        <div class="col results-summary-container">
          <div class="results-title">HIGH RISK</div>
          <div class="results-outcome">
            {{ high }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "risks",
    "followup",
    "unresolved",
    "severity",
    "low",
    "medium",
    "high",
  ],
};
</script>

<style scoped>
.type-low {
  color: green;
  font-weight: bold;
}
.type-medium {
  color: orange;
  font-weight: bold;
}
.type-high {
  color: red;
  font-weight: bold;
}
.top-results-summary > h2 {
  margin: 0;
  padding: 0;
  padding: 0.5rem 10rem 0.5rem 0.5rem;
  border-bottom: #c9c9c9 1px solid;
}
.bot-results-summary {
  display: flex;
}
.results-summary-container {
  padding: 1rem;
}
.results-summary-container > .results-title {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #999;
}
.results-summary-container > .results-outcome {
  color: #999;
  font-weight: 700;
}
</style>