<template>
  <div class="row justify-content-center formContainer">
    <div class="col-sm-6 form">
      <img src="../assets/thrive4life-logo.png" class="logoImage" />
      <img src="../assets/loginHeader.svg" class="headerImage" />
      <form @submit.prevent="submit">
        <base-input
          v-model="password"
          placeholder="New Password.."
          type="password"
          required
          autofocus
          @input="v$.password.$touch()"
          @blur="v$.password.$touch()"
        />
        <p class="text-danger" v-if="v$.password.$error">
          Password is required
        </p>
        <base-input
          v-model="confirmPassword"
          placeholder="Confirm Password.."
          type="password"
          required
          @input="v$.confirmPassword.$touch()"
          @blur="v$.confirmPassword.$touch()"
        />
        <p class="text-danger" v-if="v$.confirmPassword.required.$invalid">
          Confirm Password is required
        </p>
        <p class="text-danger" v-if="v$.confirmPassword.sameAs.$invalid">
          Confirm password does not match with password
        </p>
        <base-input
          type="submit"
          class="btn btn-primary"
          value="Reset Password"
        />
      </form>
      <p class="text-center">
        <router-link class="text-primary text-small" to="/login"
          >Return to login</router-link
        >
      </p>
    </div>
    <div class="col-sm-6 info">
      <h2 class="card-title">Thrive4Life Assessment Platform</h2>
      <p class="text-center">
        Your employer has requested you to use this training and assessment
        tool, so that they can ensure that you are able to work safely and
        comfortably.
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";

export default {
  name: "forgot-password",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmPassword: "",
      password: "",
      uid: this.$route.query.uid,
      token: this.$route.query.token,
    };
  },
  validations() {
    return {
      confirmPassword: { required, sameAs: sameAs(this.password) },
      password: { required },
    };
  },
  components: {
  },
  methods: {
    submit: function () {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      axios
        .post("/api/password_change_reset", {
          uid: this.uid,
          token: this.token,
          confirm_password: this.password,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style>
.formContainer {
  background: white;
  position: relative;
  padding: 10px;
}

.form {
  width: 60%;
  border-right: 1px solid #7889a5;
}

.logoImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
}

.headerImage {
  width: 50%;
  display: block;
  margin: 0 auto 50px auto;
}

.info {
  padding: 100px 0;
  text-align: center;
}
</style>
