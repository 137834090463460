<template>
  <div class="container-fluid pt-5 pt-md-8">
    <div class="card">
      <div class="card-body">
        <Spinner v-if="isLoading"/>
        <div class="content-wrapper" v-if="!isLoading">
          <img
            src="../assets/payment-success.png"
            v-if="!hasFailed && !hasError"
          />
          <img
            src="../assets/payment-failed.png"
            v-if="hasFailed || hasError"
          />
          <div class="message-wrapper">
            <p
              :class="{
                'text-danger': hasFailed || hasError,
              }"
            >
              <b>{{ message }}</b>
            </p>
            <base-button
              type="primary"
              @click="$router.replace('/licence-management')"
              >Return to Licence Management</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { currency } from "../helpers/formatters";
export default {
  name: "Stripe Successful",
  data() {
    return {
      isLoading: true,
      hasFailed: false,
      hasError: false,
      message: "",
      amount: -1,
    };
  },
  created() {
    const sessionID = this.$route.params.sessionId;

    if (!sessionID) {
      console.error("Missing session ID");
      return;
    }
    axios
      .post("/api/licence/register", {
        session_id: sessionID,
      })
      .then((res) => {
        switch (res.data?.status) {
          case "paid":
            this.hasFailed = false;
            this.amount = res.data?.amount;
            this.message = `Thank you for your purchase. Your payment for ${currency(
              res.data.amount / 100
            )} was successful. You now have ${
              res.data.credits
            } licence credits available.`;
            break;
          case "failed":
            this.hasFailed = true;
            this.amount = res.data?.amount;
            this.message = `Your payment for ${currency(
              res.data.amount / 100
            )} has failed or was cancelled - please try again.`;
            break;
          case "error":
          default:
            this.hasError = true;
            this.message = `There was an error processing your payment - please try again. (${res.data.error})`;
            break;
        }
        this.isLoading = false;
      });
  },
};
</script>
<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 2rem;
}
.content-wrapper img {
  width: 100%;
  max-width: 400px;
}

.content-wrapper .message-wrapper {
  max-width: 700px;
}
</style>