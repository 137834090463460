<template>
  <div id="app">
    <router-view />
    <!-- modals -->
    <FindHelpModal/>
    <ErrorHandlingPopup />
  </div>
</template>
<script>
import ErrorHandlingPopup from "./components/errorHandlingPopup.vue";
import FindHelpModal from "./components/PopUps/FindHelpModal.vue";
export default {
  name: "App",
  components: {
    ErrorHandlingPopup,
    FindHelpModal,
  },
  data() {
    return {
      searchdropdown: true,
      selectdropdown: true,
      itemList: [
        {
          id: 1,
          name: "Display Screen Equipmnet Assesment",
          icon: ["fal", "file-alt"],
        },
        { id: 2, name: "RWS Assesment", icon: ["fal", "file-alt"] },
        { id: 3, name: "Mental Health Assesment", icon: ["fal", "file-alt"] },
      ],
      test: [
        {
          date: "10 Sep 2020",
          desc: "Hello World",
        },
        {
          date: "12 Sep 2020",
          desc: "Hello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello WorldHello World",
        },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #fafafa;
}

.page-content {
  padding: 20px !important;
}

button {
  font-family: "Roboto", sans-serif;
}

i {
  font-family: "FontAwesome" !important;
}

.blocker {
  display: none;
}
</style>