<template>
  <div class="card">
    <div class="card-header">
      <p class="question">{{ question }}</p>
      <issue-severity-text
        v-if="allowStatusChange && severity > 0"
        :severity="severity"
        class="mt-2 mb-0"
      />
    </div>
    <div class="card-body">
      <p class="answer">{{ answer }}</p>
      <p class="textbox-text" v-if="textbox_text">"{{ textbox_text }}"</p>
      <p class="solution" v-if="showSolution">{{ solution }}</p>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <NotesPopUp :notes="notes" :answerId="id" :allowAddNotes="allowAddNotes"></NotesPopUp>
        </div>
        <div class="col">
          <IssuePopUp
            :severity="severity"
            :status="status"
            :answerId="id"
            v-if="allowStatusChange"
          ></IssuePopUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IssueSeverityText from "../components/IssueSeverityText.vue";
import NotesPopUp from "./NotesPopUp";
import IssuePopUp from "./IssuePopUp";
export default {
  name: "AssesmentResult.vue",
  components: {
    IssueSeverityText,
    NotesPopUp,
    IssuePopUp,
  },
  props: {
    id: Number,
    severity: { type: String, default: "none" },
    question: String,
    answer: String,
    solution: { type: String, default: "No Issue." },
    status: String,
    notes: { type: Array },
    textbox_text: String,
    showSolution: {
      type: Boolean,
      default: false,
    },
    allowAddNotes: {
      type: Boolean,
      default: false,
    },
    allowStatusChange: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      myStatus: "",
    };
  },
  computed: {
    answerTitle: function () {
      const statusValue = this.myStatus || this.status;
      return statusValue === "noissue" ? "no issue" : statusValue;
    },
    statusText() {
      return this.myStatus || this.status;
    },
  },
};
</script>

<style scoped>
.card {
  min-height: 150px;
}

.card-header,
.card-footer {
  background: #f0f0f0;
}

.question {
  font-weight: 600;
  margin: 0;
}

.solution {
  width: 90%;
}

.answer {
  text-transform: uppercase;
  font-weight: bold;
}

.textbox-text {
  font-weight: bold;
}
</style>