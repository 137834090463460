<template>
  <div class="container-fluid">
    <router-link
      :to="{ name: $route.name }"
      class="
        active
        router-link-exact-active
        h2
        text-white text-uppercase
        d-lg-inline-block
      "
      >{{ title }}</router-link
    >
  </div>
</template>
<script>
import { capitalize, noStartSlash } from "../helpers/formatters";
export default {
  computed: {
    title() {
      const titles = this.$route.matched
        .slice(1)
        .map((item) => {
          if (item.meta.pageTitle === "") {
            return "";
          }
          return item.meta.pageTitle || item.name || noStartSlash(item.path);
        })
        .filter((el) => el !== "");
      return capitalize(titles.join(" / "));
    },
  },
};
</script>
