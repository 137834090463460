<template>
  <div class="text-center">
    <p>
      You have
      <span class="credits">{{ creditText }}</span> remaining
    </p>
    <base-button size="sm" type="secondary" @click="loadData">Refresh</base-button>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["companyId"],
  data() {
    return {
      credits: -1,
    };
  },
  computed: {
    creditText() {
      return this.credits >= 0 ? `${this.credits} credits` : "--";
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.companyId) {
        return;
      }
      this.credits = -1;
      axios.get(`/api/companies/credits/${this.companyId}`).then((res) => {
        this.credits = res.data.credits;
      });
    },
  },
};
</script>
<style scoped>
p {
  font-size: 1.5rem;
  text-align: center;
}
.credits {
  font-size: 3.5rem;
  font-weight: bold;
  display: block;
}
</style>