<template>
  <div>
    <h3>Results: {{ $route.meta.description }}</h3>
    <Spinner v-if="isLoading" />
    <div class="output" v-if="!isLoading && results.length">
      <div class="group" v-for="(item, i) in results" :key="i">
        <div class="single" v-for="({data}, j) in columns" :key="`item-${i}-${j}`">
          <span class="title">{{ capital(data) }}:</span>
          <span class="content">{{ item[data] }}</span>
        </div>
      </div>
    </div>
    <p v-if="!isLoading && results.length === 0">
      No entries available for this report.
    </p>
  </div>
</template>
<script>
import axios from "axios";
import { capitalize } from "../../helpers/formatters";

export default {
  props: {
    companyId: {
      type: Number,
      default: -1,
    },
    departmentId: {
      type: Number,
      default: -1,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    apiUrl: {
      type: String,
      default: "",
    },
    needsDepartment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      results: [],
      isLoading: true,
    };
  },
  created() {
    if (!this.apiUrl) {
      throw "apiURL prop required";
    }
    if (!this.columns || this.columns.length === 0) {
      throw "columns prop required";
    }
    this.update();
  },
  watch: {
    companyId() {
      this.update();
    },
    departmentId() {
      if (!this.needsDepartment) {
        return;
      }
      this.update();
    },
    apiUrl() {
      this.update();
    },
    columns() {
      this.update();
    },
  },
  computed: {
    hasCompany() {
      return this.companyId > 0;
    },
    hasDepartment() {
      return this.departmentId > 0;
    },
  },
  methods: {
    update() {
      if (!this.hasCompany) {
        return;
      }
      if (this.needsDepartment && !this.hasDepartment) {
        return;
      }

      let url = `${this.apiUrl}/${this.companyId}`;
      if (this.needsDepartment) {
        url += `/${this.departmentId}`;
      }

      this.isLoading = true;
      this.results = [];

      this.$emit("loadStart");
      axios.get(url).then((res) => {
        this.isLoading = false;
        this.results = res.data;
        this.$emit("loadEnd");
      });
    },
    capital(str) {
      return capitalize(str);
    },
  },
};
</script>
<style scoped>
.group {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid black;
}
.single {
  margin-bottom: 1rem;
}
.title {
  font-weight: bold;
  display: block;
}
</style>