<template>
  <div class="card">
    <div class="card-body">
      <div class="row title">
        <div class="col">
          <h3>{{ name }}</h3>
        </div>
        <div class="col text-right user-detail-date">DATE: {{ date }}</div>
      </div>
      <div class="row">
        <div class="col">
          <span class="heading">COMPANY: </span>
          <span class="record">{{ company }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="heading">EMAIL: </span>
          <span class="record">{{ email }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="heading">DEPARTMENT: </span>
          <span class="record">{{ department }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="heading">ASSESSMENT TYPE: </span>
          <span class="record">{{ assessment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    company: {
      type: String,
    },
    department: {
      type: String,
    },
    email: {
      type: String,
    },
    assessment: {
      type: String,
    },
  },
};
</script>

<style scoped>
.user-detail-container {
  width: fit-content;
  max-width: 30vw;
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.user-detail-date {
  font-weight: bold;
  color: #999;
}
.row {
  margin-top: 15px;
}
.row.title {
  margin-top: 0;
}
.heading {
  color: #999;
  font-weight: 400;
  display: inline-block;
  width: 200px;
}
.record {
  font-weight: bold;
  word-wrap: break-word;
  display: inline-block;
}
</style>