<template>
  <div>
    <Spinner v-if="loading" />
    <base-input
      v-if="list.length == 1"
      type="text"
      :value="list[0].name"
      disabled="true"
      readonly="true"
    />
    <select
      class="form-control"
      v-model="selected"
      v-if="list.length !== 1"
      :disabled="disabled || list.length === 0"
      @change="onChange"
    >
      <option :value="-1" selected>Select Assessment Type...</option>
      <option v-for="item in list" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["modelValue", "disabled"],
  data() {
    return {
      list: [],
      selected: this.modelValue,
      loading: true,
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    modelValue() {
      this.selected = this.modelValue;
    },
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.selected);
    },
    setSelected(newVal) {
      this.selected = newVal;
      this.onChange();
    },
    loadData() {
      this.loading = true;
      axios.get("/api/assessments").then((res) => {
        this.loading = false;
        this.list = res.data;
        if (this.list.length === 1) {
          this.setSelected(this.list[0].id);
        }
      });
    },
  },
};
</script>
