<template>
  <modal
    v-model:show="$store.state.showHelpModal"
    modalClasses="modal-dialog-centered modal-lg"
    @close="close"
  >
    <div class="container-fluid">
      <div class="profile-modal-title">Sorry to hear you're stuck!</div>
      <div class="row">
        <div class="col">
          <img src="../assets/question.svg" class="questionImage" />
        </div>
        <div class="col align-self-center">
          We'll try and get you up and running right away. Please head over to
          our
          <a
            target="_blank"
            href="https://www.thrive4.life/assessment-tool/find-help/"
            ><b>help page</b></a
          >
          for a detailed User Guide and FAQ pages.<br /><br />
          If you can't find an answer to your query please email
          <a href="mailto:info@thrive4.life"><b>info@thrive4.life </b> </a>with
          a description of your issue and we'll be in touch!
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <base-button type="secondary" @click="close">Close</base-button>
    </template>
  </modal>
</template>

<script>
export default {
  methods: {
    close() {
      this.$store.commit("hideHelpModal");
    },
  },
};
</script>

<style scoped>
.profile-modal-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.questionImage {
  width: 100%;
}
</style>
