<template>
  <div>
    <Spinner v-if="isLoading" />
    <DataTable
      :data="data"
      :columns="columns"
      :order="[0, 'desc']"
      v-if="!isLoading && data.length"
    />
    <p v-if="!isLoading && data.length === 0">You have not purchased any licences yet.</p>
  </div>
</template>
<script>
import axios from "axios";
import { currency } from "../../helpers/formatters";
export default {
  data() {
    const isThriveAdmin = this.$store.state.user.isThriveAdmin;

    return {
      isLoading: false,
      data: [],
      columns: [
        { data: ({ purchase_date, purchase_date_machine }, purpose) => purpose === "sort" ? purchase_date_machine : purchase_date, title: "Date" },
        { data: "user.name", title: "Name" },
        { data: "user.company", title: "Company", visible: isThriveAdmin },
        { data: "user.department", title: "Department" },
        { data: "count", title: "Credits", className: "text-center" },
        {
          data: (el) => el.total_price / 100,
          title: "Total Price",
          className: "text-right",
          formatter: currency,
        },
      ],
    };
  },
  async created() {
    this.data = await axios.get("/api/licence/history").then(res => res.data);
    this.isLoading = false;
  },
};
</script>