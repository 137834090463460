<template>
  <form @submit.prevent="sendFile" action enctype="application/json">
    <label for="csv-upload" class="mb-0 btn-primary btn btn-sm">
      <font-awesome-icon :icon="icon" class="mr-1" />Upload Users (CSV)
    </label>
    <input name="file" type="file" id="csv-upload" ref="file" @change="readFile" accept=".csv" />
  </form>
</template>

<script>
import axios from "axios";
import papaparse from "papaparse"
import { faFileCsv } from "@fortawesome/free-solid-svg-icons"
export default {
  data() {
    return {
      file: "",
      tableData: [],
      tableRow: [],
      icon: faFileCsv,
    };
  },
  methods: {
    readFile() {
      /* return first object in FileList */
      var file = event.target.files[0];
      papaparse.parse(file, {
        header: true,
        transformHeader: function (h) {
          return h.trim().toLowerCase();
        },
        complete: function (results) {
          var data = results.data;
          data.pop(); // empty row at the end
          axios
            .post("/api/users", {
              users: data,
            })
            .then((res) => {
              if (res.status === 201) {
                location.reload();
              }
            });
        },
      });
    },
    sendFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post("/api/users", {
          users: this.file,
        })
        .then((res) => {
          if (res.status === 201) {
            location.reload();
          }
        });
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>