<template>
  <div>
    <div class="ui">
      <base-button type="primary" v-if="myNotes.length" @click="isOpen = true"
        >View Notes ({{ myNotes.length }})</base-button
      >
      <base-button
        type="primary"
        :icon="addIcon"
        @click="showForm"
        v-if="allowAddNotes"
        >Add Note</base-button
      >
    </div>

    <teleport to="#modal-layer">
      <modal v-model:show="isFormOpen" v-if="allowAddNotes && isFormOpen">
        <h3>Add a note</h3>
        <div class="form-group">
          <textarea
            v-model="notesTextbox"
            placeholder="Additional notes..."
            class="form-control"
          ></textarea>
        </div>
        <base-button type="primary" @click="submitNotes">Save Note</base-button>
        <base-button type="secondary" @click="hideForm">Cancel</base-button>
      </modal>

      <modal bodyClasses="notes-modal-body" v-if="isOpen" v-model:show="isOpen">
        <template v-slot:header>
          <h3>Notes</h3>
        </template>
        <template v-slot:default>
          <div ref="notesModalBody" class="notes-content">
            <p
              class="note-entry"
              v-for="(entry, index) in myNotes"
              :key="index"
            >
              <strong>{{ entry.created_at }}</strong> {{ entry.note }}
            </p>
          </div>
        </template>
        <template v-slot:footer>
          <base-button type="secondary" @click="isOpen = false"
            >Close</base-button
          >
        </template>
      </modal>
    </teleport>
  </div>
</template>

<script>
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
export default {
  props: {
    notes: {
      type: Array,
      default: () => [{ note: "No additional notes", created_at: "N/A" }],
    },
    answerId: {
      type: Number,
    },
    allowAddNotes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      isFormOpen: false,
      notesTextbox: "",
      addIcon: faPlus,
      myNotes: [],
    };
  },
  created() {
    this.myNotes = [...this.notes];
  },
  methods: {
    showForm() {
      this.notesTextbox = "";
      this.isFormOpen = true;
    },
    hideForm() {
      this.isFormOpen = false;
    },
    submitNotes() {
      if (!this.answerId) {
        throw "Missing answerId";
      }

      const note = this.notesTextbox;

      if (!note) {
        this.hideForm();
        return;
      }

      axios
        .post("/api/userassessment/note", {
          user_assessment_answer_id: this.answerId,
          note: note,
        })
        .then((res) => {
          this.myNotes.push({
            note: res.data.note,
            created_at: res.data.created_at,
          });
          this.hideForm();
        });
    },
  },
};
</script>

<style>
.modal-body.notes-modal-body {
  max-height: 55vh;
  overflow-y: scroll;
  padding-top: 0;
  margin-top: 0;
}
</style>

<style scoped>
strong {
  font-weight: bold;
}
.ui {
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
  column-gap: 1rem;
}
</style>