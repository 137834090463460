<template>
  <div class="row justify-content-center formContainer">
    <div class="col-sm-6 form">
      <img src="../assets/thrive4life-logo.png" class="logoImage" />
      <img src="../assets/loginHeader.svg" class="headerImage" />
      <form @submit.prevent="logUserIn">
        <label>Email:</label>
        <base-input placeholder="Email" v-model="email" required @input="v$.email.$touch()" @blur="v$.email.$touch()">
        </base-input>
        <p class="text-danger" v-if="v$.email.$error">
          Please enter a valid email address.
        </p>

        <label>Password:</label>
        <base-input placeholder="Password" type="password" v-model="password" required @input="v$.password.$touch()"
          @blur="v$.password.$touch()"></base-input>
        <p class="text-danger" v-if="v$.password.$error">
          Please enter a password.
        </p>
        <base-input type="submit" class="btn btn-primary" value="Sign In" />
      </form>
      <p class="text-danger text-center" v-if="error">{{ error }}</p>
      <p class="text-center">
        <router-link class="text-primary text-small" to="/forgot-password">I’ve forgotten my password</router-link>
      </p>
    </div>
    <div class="col-sm-6 info">
      <h2 class="card-title">Thrive4Life Assessment Platform</h2>
      <p class="text-center">
        Your employer has requested you to use this training and assessment
        tool, so that they can ensure that you are able to work safely and
        comfortably.
      </p>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "login",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
      password: "",
      returnUrl: this.$route.query.returnUrl,
      error: "",
    };
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },
  methods: {
    async logUserIn() {
      this.$swal.close();

      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      await this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });

      if (this.$store.state.user) {
        return this.$router.push(this.returnUrl || "/dashboard");
      }

      this.error = "Incorrect username or password";
    },
  },
};
</script>
<style>
.formContainer {
  background: white;
  position: relative;
  padding: 10px;
}

.form {
  width: 60%;
  border-right: 1px solid #7889a5;
}

.logoImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
}

.headerImage {
  width: 50%;
  display: block;
  margin: 0 auto 50px auto;
}

.info {
  padding: 100px 0;
  text-align: center;
}
</style>
