<template>
  <div>
    <base-input
      v-if="forceValue"
      type="text"
      :value="'Department: ' + forceValue"
      disabled="true"
      readonly="true"
    />
    <div v-if="!forceValue">
      <Spinner v-if="loading"/>
      <base-input
        v-if="list.length == 1"
        type="text"
        :value="'Department: ' + list[0].name"
        disabled="true"
        readonly="true"
      />
      <select
        class="form-control"
        v-model="selected"
        v-if="list.length !== 1"
        :disabled="disabled || list.length === 0"
      >
        <option :value="-1" selected>Select Department...</option>
        <option v-for="item in list" :key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      default: -1,
    },
    forceValue: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      list: [],
      selected: this.modelValue,
      loading: false,
    };
  },
  created() {
    this.clear();
    this.update();
  },
  watch: {
    companyId() {
      this.clear();
      this.update();
    },
    selected() {
      this.$emit("update:modelValue", this.selected);
    },
  },
  methods: {
    clear() {
      this.list = [];
      this.selected = this.modelValue;
    },
    update() {
      if (this.forceValue) {
        return;
      }
      this.list = [];
      if (this.companyId > 0) {
        this.loadData();
      }
    },
    loadData() {
      if (!this.companyId > 0) {
        return;
      }
      this.clear();
      this.loading = true;
      const self = this;
      axios.get("/api/companies/" + this.companyId).then((res) => {
        this.loading = false;
        self.list = res.data.departments;
        if (self.list.length === 1) {
          self.selected = self.list[0].id;
        }
      });
    },
  },
};
</script>
