<template>
  <div class="drop-down-items">
    <base-input
      class="search-dropdown"
      v-model="itemQuery"
      :placeholder="placeholder"
      type="text"
      v-if="showSearch"
    />
    <div
      class="drop-down-item"
      v-for="item in filteredItems"
      :key="item.id"
      @click="selectItem(item)"
    >
      <font-awesome-icon :icon="iconItem" />
      {{ item.name }}
    </div>
    <div
      class="drop-down-item"
      v-if="filteredItems.length < 1"
      style="
        color: #c7c7c7;
        font-size: smaller;
        width: 100%;
        text-align: center;
      "
    >
      No Item Matches your Query
    </div>
  </div>
</template>

<script>
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "SearchDropdown",
  data() {
    return {
      itemQuery: "",
      selected: "",
      iconItem: faArrowRight,
    };
  },
  props: {
    items: Array,
    placeholder: String,
    showSearch: { type: Boolean, default: true},
    handleSelect: { type: Function },
  },
  methods: {
    selectItem(item) {
      this.itemQuery = "";
      this.selected = item;
      if (this.handleSelect) {
        this.handleSelect(item);
      }
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.itemQuery.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
.multi-select {
  text-align: left;
  cursor: pointer;
}

.drop-down-items {
  visibility: initial;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  box-shadow: 0 3px 4px rgba(77, 77, 77, 0.15);
  padding: 10px;
  width: 250px;
  min-width: fit-content;
  z-index: 1;
  font-size: 18px;
}

.drop-down-item {
  font-size: 18px;
  color: black;
  cursor: pointer;
  width: fit-content;
}

.drop-down-item:hover {
  background: #ececec;
}

.filter-bar input:focus {
  border: none;
  outline: none;
}

.search-dropdown {
  background-color: #fafafa;
  border: 2px solid #f1f1f1;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.search-dropdown:focus {
  border: 2px solid #e5e5e5;
  outline: none;
}

.search-dropdown::placeholder {
  color: #afafaf;
  font-family: Raleway, sans-serif;
  font-size: 15px;
}

.search-bar {
  font-size: 14px;
  margin: 0 10px;
  text-transform: none;
  color: black;
  font-weight: 500;
}
.line {
  border-top: 1px solid #f1f1f1;
}
</style>
