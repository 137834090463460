<template>
  <div>
    <h3 class="mb-4">Buy Licence Credits</h3>
    <Slider
      v-model="v$.value.$model"
      :min="minValue"
      :max="maxValue"
      :lazy="true"
      showTooltip="drag"
    />
    <div class="form-group mt-4">
      <h4>Total Credits To Buy (minimum {{ minValue }}):</h4>
      <base-input
        type="text"
        v-model="v$.value.$model"
        placeholder="Add Credits"
        class="form-control"
      ></base-input>
      <p v-if="hasValueError" class="text-danger">
        Please choose between {{ minValue }} and {{ maxValue }} credits.
      </p>
    </div>
    <div class="cart">
      <p><span class="label">Buying:</span> {{ value }} licences</p>
      <p><span class="label">Price:</span>{{ priceText }}</p>
      <p>The price displayed above includes VAT at the rate of 20%.</p>
      <div>
        <base-button type="primary" @click="doPay" :disabled="!canSubmit"
          >Buy Now</base-button
        >
        <Spinner v-if="isPaying" class="ml-2" />
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import { currency } from "../helpers/formatters";
import useVuelidate from "@vuelidate/core";
import { required, numeric, minValue, maxValue } from "@vuelidate/validators";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

export default {
  components: { Slider },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: 5,
      minValue: 5,
      maxValue: 1000,
      price: 0, // in pence
      isLoadingPrice: false,
      isPaying: false,
      debounceTimer: null,
      debounceInterval: 500,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    };
  },
  validations() {
    return {
      value: {
        required,
        numeric,
        minValue: minValue(this.minValue),
        maxValue: maxValue(this.maxValue),
      },
      price: {
        numeric,
        minValue: minValue(0),
      },
    };
  },
  mounted() {
    this.loadPrice();
  },
  beforeUnmount() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }
  },
  computed: {
    priceText() {
      return this.debounceTimer || this.hasValueError || this.isLoadingPrice
        ? "--"
        : currency(this.price / 100); // price is in pence
    },
    canSubmit() {
      return !(
        this.debounceTimer ||
        this.hasValueError ||
        this.isLoadingPrice ||
        this.isPaying
      );
    },
    hasValueError() {
      return this.v$.value.$errors.length > 0;
    },
  },
  watch: {
    value() {
      // debounce
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.debounceTimer = null;
        this.loadPrice();
      }, this.debounceInterval);
    },
  },
  methods: {
    loadPrice() {
      this.v$.$validate();
      if (this.hasValueError) {
        return;
      }
      this.isLoadingPrice = true;
      axios
        .post("/api/licence/amount", {
          count: parseInt(this.value),
        })
        .then((res) => {
          this.price = res.data.total_price;
          this.isLoadingPrice = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async doPay() {
      this.v$.$validate();
      if (!this.canSubmit || this.v$.$invalid) {
        return;
      }

      this.isPaying = true;

      const requestPayload = {
        count: this.value,
        price: this.price,
      };

      try {
        const res = await axios.post("/api/licence/checkout", requestPayload);

        this.sessionID = res.data?.id;

        if (!this.sessionID) {
          throw "Failed to get sessionId";
        }

        const stripe = await loadStripe(this.publishableKey);
        stripe.redirectToCheckout({ sessionId: this.sessionID });
      } catch (error) {
        console.error("Error making payment: ", error);
      }

      this.isPaying = false;
    },
  },
};
</script>
<style scoped>
.cart {
  padding: 1rem;
  background: #ebf6f4;
  border-radius: 10px;
}
.cart .label {
  font-weight: bold;
  width: 70px;
  display: inline-block;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
