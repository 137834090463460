<template>
  <div class="container-fluid pt-5 pt-md-8">
    <div class="card">
      <div class="card-body">
        <p v-if="isLoading">
          <Spinner></Spinner>
        </p>
        <div class="action-btns" v-if="!isLoading">
          <base-button type="primary" size="sm" :icon="iconCreateUser" @click="showCreateUser = true">Create User
          </base-button>
          <ExportBtn />
          <base-button type="primary" size="sm" :icon="iconEdit" :disabled="!canEdit" @click="editUser">Edit Selected
            User</base-button>
          <base-button type="primary" size="sm" :icon="iconAssign" :disabled="selectedUsers.length === 0"
            @click="showAssignPopup">Assign Assessment(s)</base-button>
          <base-button size="sm" type="primary" :icon="iconDelete" :disabled="!canDelete" @click="deleteUser">Delete
            User(s)</base-button>
          <base-button size="sm" type="primary" :icon="iconArchive" :disabled="!canArchive" @click="archiveAssessment">
            Archive Assessment(s)</base-button>
        </div>
        <div class="mt-4" v-if="!isLoading">
          <dropdown-button v-on:click="searchdropdown = !searchdropdown">
            {{
                selectedItem ? selectedItem.name : "Filter by assessment"
            }}
          </dropdown-button>
          <search-dropdown v-if="searchdropdown" :items="itemList" placeholder="Search assessments..."
            class="searchDropdown" :handleSelect="onSelectAssessment" />
        </div>
        <UsersTableWrapper ref="dataTable" v-if="!isLoading" :assessmentId="selectedItem ? selectedItem.id : 0"
          @selectionChanged="onSelectionChanged" />
      </div>
    </div>
    <AssignAssessmentPopUp ref="assignPopup"></AssignAssessmentPopUp>
    <CreateUser :show="showCreateUser" :user="user" @close="showCreateUser = false"></CreateUser>
    <AdminEdit ref="adminEdit" :user="user"></AdminEdit>
  </div>
</template>

<script>
import axios from "axios"
import DropdownButton from "@/components/Buttons/DropdownButton.vue";
import SearchDropdown from "@/components/SearchDropdown";
import UsersTableWrapper from "@/components/SortableTables/UsersTableWrapper";
import AdminEdit from "@/components/PopUps/AdminEdit";
import ExportBtn from "@/components/Buttons/ExportImportUsers";
import AssignAssessmentPopUp from "@/components/PopUps/AssignAssessment";
import CreateUser from "@/components/PopUps/CreateUser";
import {
  faBoxArchive,
  faUserPlus,
  faUserXmark,
  faPaste,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
export default {
  data() {
    return {
      user: null,
      isLoading: true,
      itemList: [],
      searchdropdown: false,
      selectedItem: null,
      selectedUsers: [],
      showCreateUser: false,
      iconCreateUser: faUserPlus,
      iconAssign: faPaste,
      iconArchive: faBoxArchive,
      iconDelete: faUserXmark,
      iconEdit: faEdit,
    };
  },
  computed: {
    canEdit() {
      return this.selectedUsers.length === 1;
    },
    canDelete() {
      return this.hasSelectedUsers;
    },
    canArchive() {
      return this.hasSelectedUsers && this.hasSelectedAssessment;
    },
    hasSelectedUsers() {
      return this.selectedUsers.length > 0;
    },
    hasSelectedAssessment() {
      return this.selectedItem && this.selectedItem.id > 0;
    },
  },
  components: {
    DropdownButton,
    SearchDropdown,
    UsersTableWrapper,
    AdminEdit,
    ExportBtn,
    AssignAssessmentPopUp,
    CreateUser,
  },
  created() {
    this.user = this.$store.state.user;
    axios.get("/api/assessments").then((res) => {
      this.itemList = [
        { id: 0, name: 'No assessment filter' }, // reset option
        ...res.data.map((x) => {
          x.icon = ["fal", "file-alt"];
          return x;
        })];
      this.isLoading = false;
    });
  },
  methods: {
    onSelectAssessment(item) {
      this.searchdropdown = false;
      this.selectedItem = item;
    },
    onSelectionChanged(rowData) {
      this.selectedUsers = rowData;
    },
    showAssignPopup() {
      if (!this.hasSelectedUsers) {
        console.error("no users selected");
        return;
      }
      this.$refs.assignPopup.show(this.selectedUsers);
    },
    editUser() {
      if (!this.canEdit) {
        console.error("can't edit");
        return;
      }
      this.$refs.adminEdit.show(this.selectedUsers[0]);
    },
    deleteUser() {
      if (!this.canDelete) {
        console.error("can't delete");
      }
      const users = this.selectedUsers.map(el => el.id);
      this.$swal
        .fire({
          title: "Do you want to delete the selected users?",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          denyButtonText: `Don't Delete`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios({
              method: "post",
              url: "/api/users/delete",
              data: { users: users },
            })
              .then(() => {
                this.$swal.fire("Deleted Successfully", "", "success");
                this.$refs.dataTable.getUsers();
              })
              .catch((error) => {
                console.error(error);
              });
          } else if (result.isDenied) {
            this.$swal.fire("Delete Cancelled", "", "error");
          }
        });
    },
    archiveAssessment() {
      if (!this.canArchive) {
        console.error("unable to archive");
      }
      const users = this.selectedUsers.map(el => el.id);
      axios({
        method: "post",
        url: "/api/users/archive",
        data: { users: users, assessment_id: this.selectedItem.id },
      })
        .then((res) => {
          this.$refs.dataTable.getUsers();
          this.$swal.fire({
            position: "top-end",
            title: res.data,
            toast: true,
            icon: "success",
            showConfirmButton: false,
            timer: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.action-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  row-gap: 1rem;
  column-gap: 0.5rem;
}

.action-btns>* {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
</style>
