<template>
  <div class="container-fluid pt-5 pt-md-8"><Spinner /></div>
</template>
<script>
export default {
  async created() {
    const returnUrl = this.$route.query.returnUrl;

    if (!returnUrl) {
      throw "returnUrl required in query string";
    }

    if (!this.$store.state.isInitialised) {
      await this.$store.dispatch("init");
    }

    this.$router.replace(returnUrl);
  },
};
</script>