import axios from "axios";
export default function errHandler(store) {
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      switch (err.response.status) {
        case 500:
          store.dispatch("errors/create", {
            visible: true,
            text: "Something is wrong on our end. Please try again later!",
          });
          break;
        default:
          store.dispatch("errors/create", {
            visible: true,
            text: err.response.data,
          });
      }
      throw err;
    }
  );
}
