<template>
  <modal v-model:show="isVisible" @close="close">
    <h3>Create Department For {{ companyName }}</h3>
    <form @submit.prevent="doSubmit">
      <base-input
        type="text"
        v-model="departmentName"
        placeholder="Name..."
      />
      <p v-if="error" class="text-danger">{{ error }}</p>
      <base-input type="submit" class="btn-primary" />
    </form>
    <div class="text-center">
      <base-button type="secondary" @click="close">Cancel</base-button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";

export default {
  props: ["item"],
  data() {
    return {
      error: "",
      isVisible: false,
      departmentName: "",
    };
  },
  computed: {
    companyName() {
      return this.item ? this.item.company.name : "";
    },
  },
  mounted() {
    this.isVisible = this.item && this.item.company && this.item.company.id;
  },
  watch: {
    item: function (newVal) {
      this.error = "";
      this.departmentName = "";
      newVal ? (this.isVisible = true) : (this.isVisible = false);
    },
  },
  methods: {
    close() {
      this.isVisible = false;
      this.error = "";
      this.departmentName = "";
      this.$emit("close");
    },
    doSubmit() {
      if (!this.item) {
        return;
      }

      // handle errors ourselves
      axios
        .create()
        .post("/api/companies/" + this.item.company.id, {
          name: this.departmentName,
        })
        .then(() => {
          // success
          location.reload();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.error = err.response.data;
          } else {
            this.error = `Request failed: {err}`;
          }
        });
    },
  },
};
</script>

<style scoped>
</style>