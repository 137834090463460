<template>
  <modal v-model:show="isVisible" @close="close">
    <h3>Where do you usually work from?</h3>
    <div class="row">
      <div class="col d-flex flex-column radios">
        <label>
          <span>Home:</span>
          <input type="radio" value="Home" v-model="location" />
        </label>
        <label>
          <span>Work:</span>
          <input type="radio" value="Work" v-model="location" />
        </label>
        <label>
          <span>Other:</span>
          <input type="radio" value="Other" v-model="location" />
        </label>
      </div>
      <div class="col">
        <div class="text-box">
          <textarea
            name
            id="note"
            placeholder="Enter your location..."
            cols="30"
            rows="3"
            v-model="note"
          ></textarea>
        </div>
      </div>
    </div>
    <base-button type="primary" @click="updateLocation()">Submit</base-button>
    <base-button type="secondary" @click="close">Close</base-button>
  </modal>
</template>

<script>
import axios from "axios";
import { capitalize } from "../helpers/formatters";

export default {
  props: ['show'],
  data() {
    const user = this.$store.state.user;
    return {
      location: capitalize(user.location || ""),
      note: user.location_note || "",
      options: ["Home", "Work", "Other"],
      isVisible: this.show,
    };
  },
  watch: {
    show() {
      this.isVisible = this.show;
    }
  },
  methods: {
    updateLocation() {
      const data = { location: this.location, location_note: this.note };
      axios
        .put("/api/users/me", data)
        .then(() => {
          this.$store.commit("setUserLocation", data);
          this.$swal.fire({
            position: "top",
            title: "Your changes have been saved",
            toast: true,
            icon: "success",
            showConfirmButton: false,
            timer: 3500,
          });
          this.close();
        });
    },
    close() {
      this.isVisible = false;
      this.$emit("close");
    }
  },
};
</script>

<style scoped>
.radios label span {
  display: inline-block;
  width: 75px;
}
</style>